import * as _ from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { option, none, Option, some } from 'ts-option';
import uuid from 'uuid';

import airbrake from 'common/airbrake';
import FeatureFlags from 'common/feature_flags';
import { buildSuccessOption, buildTransitionalResult, TransitionSuccessType, usingSoda3EC } from '../lib/feature-flag-helpers';
import { showErrorToastNow, showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import { fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';
import {
  checkIfCollocationNeeded, collocate,
  CollocateJobFailed, CollocationJobStarted, isCollocationCompleted,
  isCollocationJobAlreadyDone, isCollocationJobCompleted, isCollocationJobNotNeeded,
  isCollocationJobRejected, isCollocationJobStarted, isCollocationMissing, isCollocationNotNeeded,
  isCoreException, pollForCollocationStatus
} from 'common/core/collocation';
import { checkStatus, defaultHeaders } from 'common/http';
import I18n from 'common/i18n';
import { extractTableAliasesFromAst } from 'common/soql/binary-tree';
import * as CompilerAPI from 'common/soql/compiler-api';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { QueryAnalysisSucceeded, QueryAnalysisFailed, QueryCompilationFailed, QueryCompilationStaged, QueryCompilationStarted, CompilationStatus, QueryCompilationSucceeded, isCompilationFailed, isCompilationSucceeded } from 'common/types/compiler';
import { BinaryTree, CompoundOp, runnableSoQLRendering, Scope, SoQLRendering, SoQLType, soqlRendering, UnAnalyzedAst, usingNBEName, TypedSelect } from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import { ClientContextVariable, ClientContextVariableCreate, Override } from 'common/types/clientContextVariable';
import { createClientContextVariable, deleteClientContextVariable, editClientContextVariable, replaceClientContextVariablesOnRevision, replaceClientContextVariablesOnView, toTypedOverrideParamString, clientContextVariablesToCreateOnly, addOverridesToVariables } from 'common/core/client_context_variables';
import { Editor } from 'common/components/SoQLEditor';
import { MAX_FRONTEND_URL_SIZE, MAX_URL_SIZE } from 'common/utilities/Constants';
import fixedEncodeURIComponent from 'common/js_utils/fixedEncodeURIComponent';

import {
  analysisSuccess, collectJoinViews, collectJoinViewsNA, compilationSuccess, lastInChain, queryMetaSuccess, replaceLastInChain, viewColumnToVQEColumn
} from '../lib/selectors';
import { RemoteStatus } from './statuses';
import { AppState, ContextualEventHandlers, DEFAULT_PAGE_SIZE, LocationParams, PaginationState, QueryFailureDetails, QueryMetaSuccess, QueryResult, CollocationInfo, OpenModalType, ToastState, ModalData, VQEColumn, UndoRedoInfo, ClientContextInfo } from './store';
import { Tab } from 'common/explore_grid/types';

type SaveViewActions = ClearSaveStatusAction | SaveViewStartedAction | SaveViewSucceededAction | SaveViewFailedAction;

export type Action = |
  AddNewParameter |
  ApplyClicked |
  ClearParameterOverrides |
  CollocationNotNeededAction |
  CollocationNeededAction |
  CollocationInProgressAction |
  CollocationCompletedAction |
  CollocationFailedAction |
  ColumnUpdated |
  ColumnsUpdated |
  CompileAction |
  CompilationStagedAction |
  CompilationStartedAction |
  CompilationFailedAction |
  CompilationSucceededAction |
  DeleteParameter |
  AnalysisFailedAction |
  AnalysisSucceededAction |
  CompilationAndAnalysisSucceededAction |
  CompilationSucceededButAnalysisFailedAction |
  CompilationFailedButAnalysisSucceededAction |
  CompilationAndAnalysisFailedAction |
  FetchGuidanceSucceededAction |
  FetchGuidanceFailedAction |
  LocationChanged |
  QueryStartedAction |
  QueryFailedAction |
  QuerySucceededAction |
  QueryMetaSucceededAction |
  ReplaceParameterList |
  ReplaceParameterOverrides |
  SaveViewActions |
  ScopeChanged |
  SetDefaultQueryText |
  SetModalTargetWindow |
  SetOpenModal |
  SetToast |
  SetQueryText |
  SetViewQueryString |
  SoQLEditorLoaded |
  ToggleSidebar |
  UndockEditor |
  UpdateParameterPendingOverride |
  UpdateUndoRedo;

export type Dispatcher = ThunkDispatch<AppState, void, Action>;
export type CurrentState = () => AppState;
type ActionThunk = ThunkAction<void, AppState, void, Action>;

export interface ApplyClicked {
  type: 'APPLY_CLICKED';
  time: Date;
}

export const applyClicked = (time: Date): ActionThunk => (dispatch: Dispatcher) => (
  dispatch({ type: 'APPLY_CLICKED', time })
);

export interface CompileAction {
  type: 'COMPILE';
  query: string;
}

export interface ScopeChanged {
  type: 'SCOPE_CHANGED';
  scope: Scope;
}

export const scopeChanged = (scope: Scope): ScopeChanged => (
  { type: 'SCOPE_CHANGED', scope }
);

export interface SetQueryText {
  type: 'SET_QUERY_TEXT';
  text: string;
}

export interface SetViewQueryString {
  type: 'SET_VIEW_QUERY_STRING';
  queryString: string;
}

export interface SoQLEditorLoaded {
  type: 'SOQL_EDITOR_LOADED';
  editor: Editor;
}

export interface CompilationStagedAction {
  type: 'COMPILATION_STAGED';
  result: QueryCompilationStaged;
  reason: string;
}
export const stageIncompleteAst = (
  incompleteAst: BinaryTree<UnAnalyzedAst>, reason: string
): CompilationStagedAction => ({
  type: 'COMPILATION_STAGED',
  result: {
    type: CompilationStatus.Staged,
    ast: incompleteAst,
    tableAliases: extractTableAliasesFromAst(incompleteAst),
  },
  reason
});

export interface CompilationStartedAction {
  type: 'COMPILATION_STARTED';
  ref: string;
  result: QueryCompilationStarted;
}
export interface CompilationFailedAction {
  type: 'COMPILATION_FAILED';
  ref: string;
  result: QueryCompilationFailed;
}
export interface CompilationSucceededAction {
  type: 'COMPILATION_SUCCEEDED';
  ref: string;
  result: QueryCompilationSucceeded;
}

export interface AnalysisSucceededAction {
  type: 'ANALYSIS_SUCCEEDED';
  ref: string;
  result: QueryAnalysisSucceeded;
}
export interface AnalysisFailedAction {
  type: 'ANALYSIS_FAILED';
  ref: string;
  result: QueryAnalysisFailed;
}

export interface CompilationAndAnalysisSucceededAction {
  type: 'COMPILATION_AND_ANALYSIS_SUCCEEDED';
  ref: string;
  compilation: QueryCompilationSucceeded;
  analysis: QueryAnalysisSucceeded;
}
export interface CompilationSucceededButAnalysisFailedAction {
  type: 'COMPILATION_SUCCEEDED_BUT_ANALYSIS_FAILED';
  ref: string;
  compilation: QueryCompilationSucceeded;
  analysis: QueryAnalysisFailed;
}
export interface CompilationFailedButAnalysisSucceededAction {
  type: 'COMPILATION_FAILED_BUT_ANALYSIS_SUCCEEDED';
  ref: string;
  compilation: QueryCompilationFailed;
  analysis: QueryAnalysisSucceeded;
}
export interface CompilationAndAnalysisFailedAction {
  type: 'COMPILATION_AND_ANALYSIS_FAILED';
  ref: string;
  compilation: QueryCompilationFailed;
  analysis: QueryAnalysisFailed;
}

export interface CollocationNotNeededAction {
  type: 'COLLOCATION_NOT_NEEDED';
  joinTargets: string[];
}
export interface CollocationNeededAction {
  type: 'COLLOCATION_NEEDED';
  joinTargets: string[];
}
export interface CollocationInProgressAction {
  type: 'COLLOCATION_IN_PROGRESS';
  jobId: string;
  joinTargets: string[];
}
export interface CollocationCompletedAction {
  type: 'COLLOCATION_COMPLETED';
  joinTargets: string[];
}
export interface CollocationFailedAction {
  type: 'COLLOCATION_FAILED';
  response: CollocateJobFailed;
  joinTargets: string[];
}
export const collocationNotNeeded = (joinTargets: string[]): CollocationNotNeededAction => ({
  type: 'COLLOCATION_NOT_NEEDED',
  joinTargets
});
export const collocationNeeded = (joinTargets: string[]): CollocationNeededAction => ({
  type: 'COLLOCATION_NEEDED',
  joinTargets
});
export const collocationInProgress = (response: CollocationJobStarted, joinTargets: string[]): CollocationInProgressAction => ({
  type: 'COLLOCATION_IN_PROGRESS',
  jobId: response.jobId,
  joinTargets
});
export const collocationCompleted = (joinTargets: string[]): CollocationCompletedAction => ({
  type: 'COLLOCATION_COMPLETED',
  joinTargets
});
export const collocationFailed = (response: CollocateJobFailed, joinTargets: string[]): CollocationFailedAction => ({
  type: 'COLLOCATION_FAILED',
  response,
  joinTargets
});


export interface LocationChanged {
  type: 'LOCATION_CHANGED';
  location: LocationParams;
}
export const locationChanged = (location: LocationParams): LocationChanged => {
  return { type: 'LOCATION_CHANGED', location };
};

export const updateTab = (tab: Tab) => (dispatch: Dispatcher, getState: CurrentState) => {
  const locationParams = {
    ...getState().locationParams,
    tab
  };
  dispatch(locationChanged(locationParams));
  getState().onHistoryUpdated(locationToUrl(getState, locationParams));
};


const updateQueryURL = (query: SoQLRendering) => (dispatch: Dispatcher, getState: CurrentState) => {
  const locationParams = {
    ...getState().locationParams,
    queryString: some(soqlRendering.unwrap(query))
  };
  dispatch(locationChanged(locationParams));
  getState().onHistoryUpdated(locationToUrl(getState, locationParams));
};

export interface UpdateParameterPendingOverride {
  type: 'UPDATE_PARAMETER_PENDING_OVERRIDE';
  parameter: ClientContextVariable;
  override: Override;
}

export const updateParameterOverride = (parameter: ClientContextVariable, override: Override) =>  (dispatch: Dispatcher) => (
  dispatch({type: 'UPDATE_PARAMETER_PENDING_OVERRIDE', parameter, override})
);

export interface UpdateUndoRedo {
  type: 'UPDATE_UNDO_REDO';
  undo: UndoRedoInfo[];
  redo: UndoRedoInfo[];
  justApplied: UndoRedoInfo;
}
const updateUndoRedo = (undo: UndoRedoInfo[], redo: UndoRedoInfo[], justApplied: UndoRedoInfo): UpdateUndoRedo => ({
    type: 'UPDATE_UNDO_REDO', undo, redo, justApplied
});

const storeUndoable = (state: AppState, queryText: string): UpdateUndoRedo => {
  const justApplied = { queryText, clientContext: state.clientContextInfo, columnMetadata: state.columns };
  const newUndo = state.undoRedoInfo.justApplied.nonEmpty ? [...state.undoRedoInfo.undo, state.undoRedoInfo.justApplied.get] : state.undoRedoInfo.undo;
  return updateUndoRedo(newUndo, [], justApplied);
};

const undoToRedo = (state: AppState): UpdateUndoRedo => {
  const newUndo = state.undoRedoInfo.undo.slice(0, -1);
  // justApplied should always be nonEmpty at this point, but /shrugs
  const newRedo = state.undoRedoInfo.justApplied.map(ja => [...state.undoRedoInfo.redo, ja]).getOrElseValue(state.undoRedoInfo.redo);
  const justApplied = state.undoRedoInfo.undo.slice(-1)[0];
  return updateUndoRedo(newUndo, newRedo, justApplied);
};

const redoToUndo = (state: AppState): UpdateUndoRedo => {
  const newRedo = state.undoRedoInfo.redo.slice(0, -1);
  // justApplied should always be nonEmpty at this point, but /shrugs
  const newUndo = state.undoRedoInfo.justApplied.map(ja => [...state.undoRedoInfo.undo, ja]).getOrElseValue(state.undoRedoInfo.undo);
  const justApplied = state.undoRedoInfo.redo.slice(-1)[0];
  return updateUndoRedo(newUndo, newRedo, justApplied);
};

export interface ToggleSidebar {
  type: 'TOGGLE_SIDEBAR';
}

export const toggleSidebar = (): ToggleSidebar => {
  return { type: 'TOGGLE_SIDEBAR' };
};

// setting the data type to any for any future modals that may need to store data
export interface SetOpenModal {
  type: 'SET_OPEN_MODAL';
  modal: OpenModalType;
  data: Option<ModalData>;
}

export const openModal = (modal: OpenModalType, modalData: Option<ModalData>): SetOpenModal => {
  return { type: 'SET_OPEN_MODAL', modal: modal, data: modalData };
};

export const closeModal = (): SetOpenModal => {
  return { type: 'SET_OPEN_MODAL', modal: OpenModalType.NONE, data: none };
};

export interface SetToast {
  type: 'SET_TOAST';
  toastState: ToastState;
}

export const showToast = (message: string, icon: Option<JSX.Element>, duration?: number) => (dispatch: Dispatcher, getState: CurrentState) => {
  // adds a little going-away-coming-back animation that helps signal the user that this is a new message
  if (getState().toastState.isOpen) {
    dispatch(clearToast());
  }
  dispatch({
    type: 'SET_TOAST',
    toastState: {
      isOpen: true,
      message: some(message),
      icon: icon,
      duration
    }
  });
};

export const clearToast = (): SetToast => {
  return {
    type: 'SET_TOAST',
    toastState: {
      isOpen: false,
      message: none,
      icon: none,
      duration: undefined
    }
  };
};

export const setQueryText = (text: string): SetQueryText => {
  return { type: 'SET_QUERY_TEXT', text };
};

export const soqlEditorLoaded = (editor: Editor): SoQLEditorLoaded => {
  return { type: 'SOQL_EDITOR_LOADED', editor};
};

export const compilationStarted = (text: Option<string>, ast: Option<BinaryTree<UnAnalyzedAst>>, ref: string): CompilationStartedAction => {
  return { type: 'COMPILATION_STARTED', ref, result: { type: CompilationStatus.Started, ast, text, ref } };
};
export const compilationFailed = (ref: string, result: QueryCompilationFailed): CompilationFailedAction => {
  return {
    type: 'COMPILATION_FAILED',
    ref,
    result
  };
};

export const compilationSucceeded = (ref: string, text: Option<string>, succeeded: QueryCompilationSucceeded): CompilationSucceededAction => {
  return {
    type: 'COMPILATION_SUCCEEDED',
    ref,
    result: {
      ...succeeded,
      text,
    }
  };
};

export const analysisFailed = (ref: string, result: QueryAnalysisFailed, attemptedAst: Option<BinaryTree<TypedSelect>>): AnalysisFailedAction => {
  return {
    type: 'ANALYSIS_FAILED',
    ref,
    result: { ...result, attemptedAst }
  };
};
export const analysisSucceeded = (ref: string, succeeded: QueryAnalysisSucceeded): AnalysisSucceededAction => {
  return {
    type: 'ANALYSIS_SUCCEEDED',
    ref,
    result: succeeded
  };
};

export const compilationAndAnalysisSucceeded = (ref: string, text: Option<string>, compilation: QueryCompilationSucceeded, analysis: QueryAnalysisSucceeded): CompilationAndAnalysisSucceededAction => {
  return {
    type: 'COMPILATION_AND_ANALYSIS_SUCCEEDED',
    ref,
    compilation: {
      ...compilation,
      text
    },
    analysis
  };
};
export const compilationSucceededButAnalysisFailed = (ref: string, text: Option<string>, compilation: QueryCompilationSucceeded, analysis: QueryAnalysisFailed, attemptedAst: Option<BinaryTree<TypedSelect>>): CompilationSucceededButAnalysisFailedAction => ({
  type: 'COMPILATION_SUCCEEDED_BUT_ANALYSIS_FAILED',
  ref,
  compilation: {
    ...compilation,
    text
  },
  analysis: {
    ...analysis,
    attemptedAst
  }
});
export const compilationFailedButAnalysisSucceeded = (ref: string, compilation: QueryCompilationFailed, analysis: QueryAnalysisSucceeded): CompilationFailedButAnalysisSucceededAction => ({
  type: 'COMPILATION_FAILED_BUT_ANALYSIS_SUCCEEDED',
  ref,
  compilation,
  analysis
});
export const compilationAndAnalysisFailed = (ref: string, compilation: QueryCompilationFailed, analysis: QueryAnalysisFailed, attemptedAst: Option<BinaryTree<TypedSelect>>): CompilationAndAnalysisFailedAction => ({
  type: 'COMPILATION_AND_ANALYSIS_FAILED',
  ref,
  compilation,
  analysis: {
    ...analysis,
    attemptedAst
  }
});

export interface FetchGuidanceSucceededAction {
  type: 'FETCH_GUIDANCE_SUCCEEDED';
  guidance: GuidanceSummaryV2;
}

export const getGuidanceSucceeded = (guidanceResponse: GuidanceSummaryV2): FetchGuidanceSucceededAction => {
  return {
    guidance: guidanceResponse,
    type: 'FETCH_GUIDANCE_SUCCEEDED'
  };
};

export interface FetchGuidanceFailedAction {
  type: 'FETCH_GUIDANCE_FAILED';
}

export const getGuidanceFailed = (): FetchGuidanceFailedAction => {
  return {
    type: 'FETCH_GUIDANCE_FAILED'
  };
};

export interface QueryStartedAction {
  type: 'QUERY_STARTED';
  successTab: Option<Tab>;
}
export interface QueryFailedAction {
  type: 'QUERY_FAILED';
  details: QueryFailureDetails;
}

export type Rows = any[];
export interface QuerySucceededAction {
  type: 'QUERY_SUCCEEDED';
  rows: Rows;
  columns: VQEColumn[];
  relevanceId: string;
  compiled: QueryCompilationSucceeded;
  analyzed?: QueryAnalysisSucceeded;
}
export interface QueryMetaSucceededAction {
  type: 'QUERY_META_SUCCEEDED';
  relevantTo: string;
  rowCount: number;
  fromAst: BinaryTree<UnAnalyzedAst>;
}

export function makeRef(): string {
  return uuid.v4();
}

const queryStarted = (successTab: Option<Tab> = none): QueryStartedAction => ({
  type: 'QUERY_STARTED',
  successTab
});
const querySucceeded = (compiled: QueryCompilationSucceeded, rows: any[], columns: VQEColumn[], analyzed?: QueryAnalysisSucceeded): QuerySucceededAction => ({
  type: 'QUERY_SUCCEEDED',
  relevanceId: uuid.v4(),
  rows,
  columns,
  compiled,
  analyzed
});
const queryFailed = (details: QueryFailureDetails): QueryFailedAction => ({
  type: 'QUERY_FAILED',
  details
});

const queryMetaSucceeded = (relevantTo: string, rowCount: number, fromAst: BinaryTree<UnAnalyzedAst>): QueryMetaSucceededAction => ({
  type: 'QUERY_META_SUCCEEDED',
  relevantTo,
  rowCount,
  fromAst
});

interface RequestQuery {
  asIfView?: string;
  clientContext: { clientContextVariables: {
    name: ClientContextVariable['name'];
    dataType: ClientContextVariable['dataType'];
    defaultValue: ClientContextVariable['defaultValue'];
  }[] };
  query: string;
  page: {
    pageSize: number;
    pageNumber: number;
  }
  parameters?: UserParameters;
  store?: StoreParameter;
}

interface StoreParameter {
  oneOf: string[]
}

interface UserParameters {
  qualified?: {
    [fourfour: string]: PossibleValueMap;
  };
  unqualified?: PossibleValueMap;
}

interface PossibleValueMap {
  [holeName: string]: {
    type: SoQLType;
    value: boolean | string | number;
  }
}

const convertCCVsToUserParameters = (fourfour: string, variables: ClientContextVariable[]): UserParameters =>
  variables.reduce((params, variable) => {
    const param = {
      // This might conflict in interesting ways with the resolution of EN-65929. At least it's easy to change back here.
      type: usingNBEName(variable.dataType),
      value: variable.overrideValue || variable.defaultValue
    };
    return _.set(params, ['qualified', fourfour, variable.name], param);
  }, {} as UserParameters);

// exported for tests, you probably don't want this
export const getQuery = async (
  fourfour: string,
  compiled: TransitionSuccessType,
  pagination: PaginationState,
  replacing: string | null,
  clientContextInfo: ClientContextInfo,
  resolveColumnMetadata: ContextualEventHandlers['resolveColumnMetadata'],
  store: string | null
): Promise<QuerySucceededAction | QueryFailedAction> => {
  const clientContextOverrides = addOverridesToVariables(clientContextInfo.variables, clientContextInfo.pendingOverrides);
  const query = compiled.fold(c => runnableSoQLRendering.unwrap(c.runnable), a => a.get.text);
  let response;
  if (usingSoda3EC()) {
    const queryWithoutPaging = compiled.fold(c => soqlRendering.unwrap(c.rendering), a => a.get.text);
    const requestQuery: RequestQuery = {
      clientContext: {
        clientContextVariables: clientContextInfo.variables.map(variable => ({
          name: variable.name,
          dataType: variable.dataType,
          defaultValue: variable.defaultValue
        }))
      },
      query: queryWithoutPaging,
      page: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize
      },
      parameters: convertCCVsToUserParameters(replacing || fourfour, clientContextOverrides),
    };

    if (replacing) {
      requestQuery.asIfView = replacing;
    }
    if (store) {
      requestQuery.store = { oneOf: [store] };
    }

    response = await fetch(`/api/v3/views/${fourfour}/query.json`, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'POST',
      body: JSON.stringify(requestQuery)
    });
  } else {
    const storeFragment = store ? `$$store=${store}` : '';
    const context = [toTypedOverrideParamString(clientContextOverrides), storeFragment].filter(s => s).join('&');
    const uri = `/resource/${fourfour}.json?$query=${encodeURIComponent(query)}&${context}`;

    if (uri.length > MAX_URL_SIZE) {
      //If the URI is too long, we have to put the query in a post body
      response = await fetch(`/api/query/${fourfour}.json?${context}`, {
        credentials: 'same-origin',
        headers: defaultHeaders,
        method: 'POST',
        body: JSON.stringify({query})
      });
    } else {
      response = await fetch(uri, {
        credentials: 'same-origin',
        headers: defaultHeaders,
        method: 'GET'
      });
    }
  }
  const rowsBody = await response.json();
  if (response.status === 200) {
    try {
      const columns = await resolveColumnMetadata(compiled, clientContextOverrides);
      return querySucceeded(compiled.left, rowsBody as Rows, columns, compiled.right.orUndefined);
    } catch (error) {
      if (error instanceof GetColumnsFailedError) {
        return queryFailed({
          message: error.message,
          errorCode: error.errorCode,
          data: error.data
        });
      } else {
        throw error;
      }
    }
  } else {
    return queryFailed(rowsBody as QueryFailureDetails);
  }
};

class GetColumnsFailedError extends Error {
  errorCode: string;
  data: unknown;
  constructor(message: string, errorCode: string, data: unknown) {
    super(message);
    this.errorCode = errorCode;
    this.data = data;
  }
}

// passed as 'resolveColumnMetadata' to the contextualEventHandlers when you're on a non-revision draft (working copy)
export const getColumnsForQuery = async (
  fourfourToQuery: string,
  fourfourToBeSaved: string,
  clientContextOverrides: ClientContextVariable[],
  compiled: TransitionSuccessType): Promise<VQEColumn[]> => {
  const query = compiled.fold(c => soqlRendering.unwrap(c.rendering), a => a.get.text);
  const uriBase = `/views/${fourfourToQuery}.json?method=getColumnsForViewWithQuery&columnBaseUid=${fourfourToBeSaved}`;
  const uri = `${uriBase}&query=${encodeURIComponent(query)}&${toTypedOverrideParamString(clientContextOverrides)}`;
  let response;
  if (uri.length > MAX_URL_SIZE) {
    response = await fetch(`${uriBase}&${toTypedOverrideParamString(clientContextOverrides)}`, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'POST',
      body: JSON.stringify(query)
    });
  } else {
    response = await fetch(uri, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'GET'
    });
  }
  const body = await response.json();
  if (response.status === 200) {
    return viewColumnToVQEColumn(body as ViewColumn[]);
  } else {
    throw new GetColumnsFailedError('Failed to get columns for query.', response.status.toString(), body);
  }
};

const updateQueryMeta = (fourfour: string, prevQueryResult: Option<QueryResult>, relevanceId: string, compiled: TransitionSuccessType): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  // PERF: if the user changes the projection, we will re-fetch the count(*)
  // which we don't always need to do, but the projection is tied to things
  // like DISTINCT and GROUP BYs so it's simpler to just re-fetch. Maybe
  // there is a clever way to get around this, but I'm not clever.
  const countName = '__explore_count_name__';
  const lastAst = lastInChain(compiled.fold(c => c.unanalyzed, a => a.get.ast));
  const countStar: UnAnalyzedAst = {
    selection: {
      exprs: [{
        expr: {
          type: 'funcall',
          function_name: 'count/*',
          args: [],
          window: null
        },
        name: { name: countName, position: { line: 0, column: 0 } }
      }],
      all_system_except: null, all_user_except: []
    },
    hints: [],
    from: null,
    where: null,
    group_bys: [],
    order_bys: [],
    joins: [],
    search: null,
    distinct: 'indistinct',
    limit: null,
    offset: null,
    having: null
  };

  const countWithoutPaging: BinaryTree<UnAnalyzedAst> =
    // We want to grab the last ast in the chain, delete the limits and offsets,
    // and then pipe it to a count(*) expression.
    // so our chain
    // select foo limit 500 offset 2 |> select bar limit 100 offset 20
    // becomes
    // select foo limit 500 offset 2 |> select bar |> count(*)
    {
      type: 'compound',
      op: CompoundOp.QueryPipe,
      left: replaceLastInChain(compiled.fold(c => c.unanalyzed, a => a.get.ast), {
        ...lastAst,
        limit: null,
        offset: null,
        order_bys: []
      }),
      right: { type: 'leaf', value: countStar }
    };

  // many interactions don't change the count of rows (ie: paging through
  // the dataset, or changing a sort). When we store the count(*) result,
  // we keep the ast that we evaluated to get it. If we are asked to update
  // the query meta, but the query |> count(*) ast we generate hasn't changed,
  // then we don't need to re-run the expensive count(*) query
  const prevQueryMeta: Option<QueryMetaSuccess> = queryMetaSuccess(prevQueryResult);

  const appState = getState();
  const canUseExistingQueryMeta = prevQueryMeta
    .map((meta: QueryMetaSuccess) => _.isEqual(meta.fromAst, countWithoutPaging) && _.isEqual(meta.clientContextVariables, appState.clientContextInfo.variables))
    .getOrElseValue(false); // it's not there, we can't use it

  if (canUseExistingQueryMeta) {
    const rowCount = prevQueryMeta.get.rowCount;
    dispatch(queryMetaSucceeded(relevanceId, rowCount, countWithoutPaging));
  } else {
    dispatch(evalAst(fourfour, countWithoutPaging, false, false, (qr: QuerySucceededAction | QueryFailedAction) => {
      if (qr.type === 'QUERY_SUCCEEDED') {
        // TYPES: unsafe
        // We actually don't know what the
        const rowCount = _.toNumber((qr.rows[0] as any)[countName]);
        dispatch(queryMetaSucceeded(relevanceId, rowCount, countWithoutPaging));
      }
    }));
  }
};

const getReplacing = (appState: AppState): string | null => appState.fourfour === appState.fourfourToQuery ? null : appState.fourfour;

const getPageSize = (paginationState: PaginationState): number => (paginationState.pageSizePendingRun.getOrElseValue(paginationState.pageSize));
const getPageForCompilation = (newPage: Option<number>, paginationState: PaginationState): number => {
  return newPage.getOrElseValue(paginationState.currentPagePendingRun.getOrElseValue(paginationState.currentPage));
};

const buildUndoRedoAction = (stack: 'undo' | 'redo') => (fourfour: string): ActionThunk =>
  async (dispatch: Dispatcher, getState: CurrentState) => {
    const appState = getState();
    const stackSize = appState.undoRedoInfo[stack].length;
    const callbackToExecute = ({ undo: swapUndoRedo, redo: swapRedoUndo }[stack]);

    // undo should be disabled if this is not the case
    if (stackSize > 0) {
      const stateToApply = appState.undoRedoInfo[stack][stackSize - 1];
      const isQueryTextChanged = appState.query.text.isEmpty || appState.query.text.get !== stateToApply.queryText;

      const areClientContextVariablesChanged = stateToApply.clientContext.variables.length !== appState.clientContextInfo.variables.length ||
        !_.isEqual(
          clientContextVariablesToCreateOnly(stateToApply.clientContext.variables),
          clientContextVariablesToCreateOnly(appState.clientContextInfo.variables)
      );
      const areClientContextOverridesOnlyChanged = !areClientContextVariablesChanged
        && !_.isEqual(stateToApply.clientContext.variables, appState.clientContextInfo.variables);

      const isColumnMetadataChanged = !_.isEqual(stateToApply.columnMetadata, appState.columns);

      if (areClientContextOverridesOnlyChanged) {
        dispatch(replaceParameterOverrides(stateToApply.clientContext.variables));
        dispatch(compileAndRunQuery(fourfour, stateToApply.queryText, callbackToExecute));
      } else if (isQueryTextChanged && isColumnMetadataChanged) {
        appState.contextualEventHandlers.undoRedoColumnMetadata(stateToApply.columnMetadata, () => {
          dispatch(compileAndRunQuery(fourfour, stateToApply.queryText, callbackToExecute));
        });
      } else if (isQueryTextChanged) {
        // Possible future optimization here: save both the queryText and the compilationSuccess object in undoRedoInfo
        // then skip talking to dsmapi and directly release the compilationSucceeded. Potentially you can do the same
        // with the querySuccess object.
        // however, the text will always need to be stored/set, as the one we get from compilationSucceeded is formatted
        // For now, I don't think its worth the added complications of bypassing the expected
        // compilation in progress -> succeeded -> running query -> succeeded states and steps
        dispatch(compileAndRunQuery(fourfour, stateToApply.queryText, callbackToExecute));
      } else if (areClientContextVariablesChanged) {
        if (appState.contextualEventHandlers.replaceAllParameters) {
          dispatch(appState.contextualEventHandlers.replaceAllParameters(
            appState.view.id,
            clientContextVariablesToCreateOnly(stateToApply.clientContext.variables),
            false,
            () => compileAndRunQuery(fourfour, stateToApply.queryText, callbackToExecute),
            (err) => console.error('error undoing while compiling query', err)
          ));
        }
      } else if (isColumnMetadataChanged) {
        appState.contextualEventHandlers.undoRedoColumnMetadata(stateToApply.columnMetadata, () => {
          dispatch(columnsUpdated(stateToApply.columnMetadata)); // Force the UI to update without re-rendering the entire VQE.
          callbackToExecute(appState, dispatch);
        });
      }
    }
  };

export const doUndo = buildUndoRedoAction('undo');
export const doRedo = buildUndoRedoAction('redo');


type HandleUndoRedo = (state: AppState, dispatch: Dispatcher, queryText: string) => void;
const storeUndo = (state: AppState, dispatch: Dispatcher, queryText: string) => {
  // don't store identical duplicate runs in the undo store
  const noChange = state.undoRedoInfo.justApplied.map(ja =>
    _.isEqual(ja.queryText, queryText) && _.isEqual(ja.clientContext, state.clientContextInfo) && _.isEqual(ja.columnMetadata, state.columns)
  ).getOrElseValue(false);
  if (noChange) return;
  const action = storeUndoable(state, queryText);
  dispatch(action);

  const { type, ...updatedHistory } = action;
  state.contextualEventHandlers.updateUndoRedoHistory({ ...updatedHistory, justApplied: option(updatedHistory.justApplied) });
};

const swapUndoRedo = (state: AppState, dispatch: Dispatcher) => {
  dispatch(undoToRedo(state));
};

const swapRedoUndo = (state: AppState, dispatch: Dispatcher) => {
  dispatch(redoToUndo(state));
};

export const storeUndoDispatchable = (): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const appState = getState();
  appState.query.text.map(qt => {
    storeUndo(appState, dispatch, qt);
  });
};

export const collocateAndRunQuery = (fourfour: string, compiled: TransitionSuccessType, handleUndoRedo = storeUndo, successTab: Option<Tab> = none): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const query = compiled.fold(c => soqlRendering.unwrap(c.rendering), a => a.get.formattedText);
  maybeCollocate(fourfour, query, getState().clientContextInfo, getState().collocationInfo, compiled, dispatch, () => {
    dispatch(runQuery(fourfour, compiled, handleUndoRedo, successTab));
  });
};

const runQuery = (fourfour: string, compiled: TransitionSuccessType, handleUndoRedo: HandleUndoRedo, successTab: Option<Tab> = none): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const appState = getState();
  dispatch(queryStarted(successTab));
  const action = await getQuery(fourfour, compiled, appState.query.paginationState, getReplacing(appState), appState.clientContextInfo, appState.contextualEventHandlers.resolveColumnMetadata, appState.locationParams.queryParams.get('store'));
  dispatch(action);
  if (action.type === 'QUERY_SUCCEEDED') {
    dispatch(updateQueryURL(compiled.fold(c => c.rendering, a => soqlRendering.wrap(a.get.formattedText))));
    dispatch(updateQueryMeta(fourfour, appState.query.queryResult, action.relevanceId, compiled));
    appState.query.text.map(queryText => {
      // fetching state again because we've updated the client context variables with their overrides
      handleUndoRedo(getState(), dispatch, queryText);
    });
  }
};

const collocationIsNeeded = async (
  fourfour: string,
  query: string,
  clientContextInfo: ClientContextInfo,
  currentCollocationInfo: Option<CollocationInfo>,
  currentJoinTargets: string[],
  dispatch: Dispatcher
): Promise<boolean> => {
  if (_.isEmpty(currentJoinTargets)) {
    dispatch(collocationNotNeeded(currentJoinTargets));
    return false;
  }
  const clientContextVariables = addOverridesToVariables(clientContextInfo.variables, clientContextInfo.pendingOverrides);
  const getCollocationStatus = async () => {
    const previousJoinTargets = currentCollocationInfo.map(ci => ci.joinTargets).getOrElseValue([]);
    if (!_.isEmpty(currentCollocationInfo) && _.isEqual(previousJoinTargets, currentJoinTargets)) {
      // we already checked the status for this particular collocation target, so use it
      return {usingStoredStatus: true, status: currentCollocationInfo.get.collocationStatus};
    } else {
      const checkResponse = await checkIfCollocationNeeded(fourfour, query, clientContextVariables);
      return {usingStoredStatus: false, status: checkResponse.status};
    }
  };
  const {usingStoredStatus, status: collocationStatus} = await getCollocationStatus();

  if (isCollocationMissing(collocationStatus)) {
    if (!usingStoredStatus) dispatch(collocationNeeded(currentJoinTargets));
    return true;
  } else if (isCollocationCompleted(collocationStatus)) {
    if (!usingStoredStatus) dispatch(collocationCompleted(currentJoinTargets));
    return false;
  } else if (isCollocationNotNeeded(collocationStatus)) {
    if (!usingStoredStatus) dispatch(collocationNotNeeded(currentJoinTargets));
    return false;
  }

  // Effectively in-progress.
  // Much easier to just ignore this and wait for the runQuery request to re-attempt collocation creation.
  return true;
};

const maybeCollocate = async (
  fourfour: string,
  query: string,
  clientContextInfo: ClientContextInfo,
  collocationInfo: Option<CollocationInfo>,
  compilationResult: TransitionSuccessType,
  dispatch: Dispatcher,
  andThen: () => void = _.noop // In practice, this is always dispatch(runQuery()), but what if.
): Promise<void> => {
  const clientContextVariables = addOverridesToVariables(clientContextInfo.variables, clientContextInfo.pendingOverrides);
  const currentJoinTargets = compilationResult.fold(c => collectJoinViews(c.analyzed), a => collectJoinViewsNA(a.get.ast));
  if (!await collocationIsNeeded(fourfour, query, clientContextInfo, collocationInfo, currentJoinTargets, dispatch)) {
    andThen();
    return;
  }

  const response = await collocate(fourfour, query, clientContextVariables);
  if (isCollocationJobAlreadyDone(response)) {
    // In theory, this code block is never run. It exists mostly for completeness and safety.

    // Update the Redux store
    if (isCollocationJobNotNeeded(response)) dispatch(collocationNotNeeded(currentJoinTargets));
    else if (isCollocationJobCompleted(response)) dispatch(collocationCompleted(currentJoinTargets));

    // Transition automatically into not-needed callback.
    andThen();
    return;
  } else if (isCollocationJobStarted(response)) {
    dispatch(collocationInProgress(response, currentJoinTargets));

    // lack of await is intentional here: we kick off the poller and let the user do other stuff in the meantime.
    pollForCollocationStatus(response.jobId, {
      onCompleted: () => {
        dispatch(collocationCompleted(currentJoinTargets));
        andThen();
      },
      onCoreException: (coreException) => dispatch(collocationFailed(coreException, currentJoinTargets))
    });
  } else if (isCoreException(response) || isCollocationJobRejected(response)) {
    dispatch(collocationFailed(response, currentJoinTargets));
  }
};

export const getApprovalsGuidance = (fourfour: string) => (dispatch: Dispatcher) => {
  fetchApprovalsGuidanceV2(fourfour)
      .then((guidanceSummary: GuidanceSummaryV2) => {
        dispatch(getGuidanceSucceeded(guidanceSummary));
      })
      .catch((error) => {
          airbrake.notify({
            error,
            context:
              'Guidance failed to fetch. We will not override the AAB default primary button on this VQE'
          });
          dispatch(getGuidanceFailed());
      });
};


export const compileAndRunQuery = (fourfour: string, query: string, handleUndoRedo = storeUndo): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const ref = makeRef();
  dispatch(compilationStarted(some(query), none, ref));
  const {channel, query: {paginationState}} = getState();
  const pageSize = getPageSize(paginationState);
  const currentPage = getPageForCompilation(none, paginationState);
  const compilerResult = await CompilerAPI.compileText(channel, query, pageSize, currentPage, getState().clientContextInfo);
  const analyzerResultOpt = usingSoda3EC()
    ? some(await CompilerAPI.analyzeText(channel, query, getState().clientContextInfo, getReplacing(getState())))
    : none;
  const result = buildTransitionalResult<Action>(compilerResult, analyzerResultOpt, {
    compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) =>
      compilationAndAnalysisSucceeded(ref, some(query), c, a),
    compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) =>
      compilationSucceededButAnalysisFailed(ref, some(query), c, a, none),
    compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) =>
      compilationFailedButAnalysisSucceeded(ref, c, a),
    compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) =>
      compilationAndAnalysisFailed(ref, c, a, none),
    compilationSucceeded: (c: QueryCompilationSucceeded) => compilationSucceeded(ref, some(query), c),
    compilationFailed: (c: QueryCompilationFailed) => compilationFailed(ref, c)
  });
  if (result.isSuccess) {
    dispatch(result.action);

    await maybeCollocate(
      fourfour, query, getState().clientContextInfo, getState().collocationInfo, result.success, dispatch, () => {
      dispatch(runQuery(fourfour, result.success, handleUndoRedo));
    });
  } else if (result.isFailure) {
    dispatch(result.action);
  }
};

const evalAst = (fourfour: string, unanalyzed: BinaryTree<UnAnalyzedAst>, pageable: boolean, shouldResolveColMetadata: boolean, complete: (qr: QuerySucceededAction | QueryFailedAction) => void): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const ref = makeRef();
  const {query: {paginationState}, clientContextInfo, contextualEventHandlers, columns } = getState();
  const resolveToSameColumns = (qs: TransitionSuccessType): Promise<VQEColumn[]> => Promise.resolve(columns);
  const resolveColumnMetadata = shouldResolveColMetadata ? contextualEventHandlers.resolveColumnMetadata : resolveToSameColumns;
  const pageSize = getPageSize(paginationState);
  const currentPage = getPageForCompilation(none, paginationState);
  const compilerResult = await CompilerAPI.compileAST(getState().channel, unanalyzed, pageSize, currentPage, pageable, getState().clientContextInfo);
  const analyzerResultOpt = usingSoda3EC()
    ? some(await CompilerAPI.analyzeAST(getState().channel, unanalyzed, getState().clientContextInfo, getReplacing(getState())))
    : none;
  const tResult = buildTransitionalResult<Action>(compilerResult, analyzerResultOpt, {
    compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) =>
      compilationAndAnalysisSucceeded(ref, none, c, a),
    compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) =>
      compilationSucceededButAnalysisFailed(ref, none, c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) =>
      compilationFailedButAnalysisSucceeded(ref, c, a),
    compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) =>
      compilationAndAnalysisFailed(ref, c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationSucceeded: (c: QueryCompilationSucceeded) => compilationSucceeded(ref, none, c),
    compilationFailed: (c: QueryCompilationFailed) => compilationFailed(ref, c)
  });
  if (tResult.isSuccess) {
    const result = await getQuery(fourfour, tResult.success, paginationState, getReplacing(getState()), clientContextInfo, resolveColumnMetadata, getState().locationParams.queryParams.get('store'));
    complete(result);
  } else if (tResult.isFailure) {
    dispatch(tResult.action);
  }
};

export type RunAst = (fourfour: string, ast: BinaryTree<UnAnalyzedAst>) => void;
export const compileAndRunAst = (fourfour: string, unanalyzed: BinaryTree<UnAnalyzedAst>, newPage: Option<number>, successTab: Option<Tab> = none): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const ref = makeRef();
  dispatch(compilationStarted(none, some(unanalyzed), ref));
  const {query: { paginationState } } = getState();
  const pageSize = getPageSize(paginationState);
  const currentPage = getPageForCompilation(newPage, paginationState); // We need to pass the page-size-to-be here in order to get the correct Runnable text

  const compilerResult = await CompilerAPI.compileAST(getState().channel, unanalyzed, pageSize, currentPage, true, getState().clientContextInfo);
  const analyzerResultOpt = usingSoda3EC()
    ? some(await CompilerAPI.analyzeAST(getState().channel, unanalyzed, getState().clientContextInfo, getReplacing(getState())))
    : none;
  const result = buildTransitionalResult<Action>(compilerResult, analyzerResultOpt, {
    compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) =>
      compilationAndAnalysisSucceeded(ref, some(soqlRendering.unwrap(c.rendering)), c, a),
    compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) =>
      compilationSucceededButAnalysisFailed(ref, some(soqlRendering.unwrap(c.rendering)), c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) =>
      compilationFailedButAnalysisSucceeded(ref, c, a),
    compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) =>
      compilationAndAnalysisFailed(ref, c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationSucceeded: (c: QueryCompilationSucceeded) => compilationSucceeded(ref, some(soqlRendering.unwrap(c.rendering)), c),
    compilationFailed: (c: QueryCompilationFailed) => compilationFailed(ref, c)
  });
  if (result.isSuccess) {
    dispatch(result.action);
    const query = (usingSoda3EC()
      ? analysisSuccess(result.analysisOpt).map(r => r.text)
      : compilationSuccess(result.compilationOpt).map(r => soqlRendering.unwrap(r.rendering))
    ).orUndefined;
    if (query) {
      await maybeCollocate(
        fourfour, query,
        getState().clientContextInfo,
        getState().collocationInfo, result.success, dispatch,
        () => { dispatch(runQuery(fourfour, result.success, storeUndo, successTab)); }
      );
    }
  } else if (result.isFailure) {
    dispatch(result.action);
  }
};

export const compileText = (query: string): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const ref = makeRef();
  dispatch(compilationStarted(some(query), none, ref));
  // we're resetting the page size here because a pageSize updated by 'LIMIT' may have just been removed
  const compilerResult = await CompilerAPI.compileText(getState().channel, query, DEFAULT_PAGE_SIZE, 1, getState().clientContextInfo);
  const analyzerResultOpt = usingSoda3EC()
    ? some(await CompilerAPI.analyzeText(getState().channel, query, getState().clientContextInfo, getReplacing(getState())))
    : none;
  const result = buildTransitionalResult<Action>(compilerResult, analyzerResultOpt, {
    compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) =>
      compilationAndAnalysisSucceeded(ref, some(query), c, a),
    compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) =>
      compilationSucceededButAnalysisFailed(ref, some(query), c, a, none),
    compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) =>
      compilationFailedButAnalysisSucceeded(ref, c, a),
    compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) =>
      compilationAndAnalysisFailed(ref, c, a, none),
    compilationSucceeded: (c: QueryCompilationSucceeded) => compilationSucceeded(ref, some(query), c),
    compilationFailed: (c: QueryCompilationFailed) => compilationFailed(ref, c)
  });
  if (result.isSuccess) {
    dispatch(result.action);
    dispatch(checkCollocation(result.success));
  } else if (result.isFailure) {
    dispatch(result.action);
  }
};

export const compileAst = (unanalyzed: BinaryTree<UnAnalyzedAst>, preparePageReset: boolean): ActionThunk => async (dispatch: Dispatcher, getState: CurrentState) => {
  const ref = makeRef();
  dispatch(compilationStarted(none, some(unanalyzed), ref));
  const {channel, query: {paginationState}} = getState();
  const pageSize = getPageSize(paginationState);
  const newPage = preparePageReset ? some(1) : none;
  const currentPage = getPageForCompilation(newPage, paginationState);
  const compilerResult = await CompilerAPI.compileAST(channel, unanalyzed, pageSize, currentPage, true, getState().clientContextInfo);
  const analyzerResultOpt = usingSoda3EC()
    ? some(await CompilerAPI.analyzeAST(channel, unanalyzed, getState().clientContextInfo, getReplacing(getState())))
    : none;
  const result = buildTransitionalResult<Action>(compilerResult, analyzerResultOpt, {
    compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) =>
      compilationAndAnalysisSucceeded(ref, some(soqlRendering.unwrap(c.rendering)), c, a),
    compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) =>
      compilationSucceededButAnalysisFailed(ref, some(soqlRendering.unwrap(c.rendering)), c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) =>
      compilationFailedButAnalysisSucceeded(ref, c, a),
    compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) =>
      compilationAndAnalysisFailed(ref, c, a, some(unanalyzed as BinaryTree<TypedSelect>)),
    compilationSucceeded: (c: QueryCompilationSucceeded) => compilationSucceeded(ref, some(soqlRendering.unwrap(c.rendering)), c),
    compilationFailed: (c: QueryCompilationFailed) => compilationFailed(ref, c)
  });
  if (result.isSuccess) {
    dispatch(result.action);
    dispatch(checkCollocation(result.success));
  } else if (result.isFailure) {
    dispatch(result.action);
  }
};

const checkCollocation = (compilation: TransitionSuccessType) => async (dispatch: Dispatcher, getState: CurrentState) => {
  const { fourfour, collocationInfo, clientContextInfo} = getState();
  const currentJoinTargets = compilation.fold(c => collectJoinViews(c.analyzed), a => collectJoinViewsNA(a.get.ast));

  if (await collocationIsNeeded(fourfour, compilation.fold(c => soqlRendering.unwrap(c.rendering), a => a.get.text), clientContextInfo, collocationInfo, currentJoinTargets, dispatch)) {
    dispatch(collocationNeeded(currentJoinTargets));
  }
};

export const stageAst = (unanalyzed: BinaryTree<UnAnalyzedAst>, reason: string): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  dispatch(stageIncompleteAst(unanalyzed, reason));
};

export const addLocation = (location: LocationParams): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  dispatch(locationChanged(location));
  const appState = getState();
  compilationSuccess(appState.query.compilationResult).forEach(cs => {
    location.queryString.forEach(qs => {
      if (soqlRendering.unwrap(cs.rendering) !== qs) {
        dispatch(compileAndRunQuery(appState.fourfourToQuery, qs));
      }
    });
  });
};

export const DEFAULT_QUERY = `SELECT * LIMIT ${DEFAULT_PAGE_SIZE} OFFSET 0`;

// this does more roundtrips than we really need -
// server could just send us a default with the ast and runnable and rendered
// forms, but that's a little messier and involves more code
// if we ever need to eek out milliseconds more performance when the app bootstraps,
// this could be a thing to revisit
export const runDefaultQuery = (channelDefault: string): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const appState = getState();
  const text = appState.locationParams.queryString.getOrElseValue(appState.query.text.getOrElseValue(channelDefault || DEFAULT_QUERY));
  dispatch(compileAndRunQuery(appState.fourfourToQuery, text));
};

export interface SetDefaultQueryText {
  type: 'SET_DEFAULT_QUERY_TEXT';
  queryText: string;
}

export const setDefaultQueryText = (queryText: string): SetDefaultQueryText => ({
  type: 'SET_DEFAULT_QUERY_TEXT',
  queryText
});

export interface UndockEditor {
  type: 'UNDOCK_EDITOR';
  undocked: boolean;
}

export const undockEditor = (undocked: boolean): UndockEditor => ({
  type: 'UNDOCK_EDITOR',
  undocked
});

export interface SetModalTargetWindow {
  type: 'SET_MODAL_TARGET_WINDOW';
  modalTargetWindow: Window | null | undefined;
}

export const setModalTargetWindow = (modalTargetWindow: Window | null | undefined): SetModalTargetWindow => ({
  type: 'SET_MODAL_TARGET_WINDOW',
  modalTargetWindow
});

// saveViewActions
export interface ClearSaveStatusAction {
  type: 'CLEAR_SAVE_STATUS';
}

const clearSaveStatus = (): ClearSaveStatusAction => ({
  type: 'CLEAR_SAVE_STATUS'
});
export interface SaveViewStartedAction {
  type: 'SAVE_VIEW_STARTED';
}

const saveViewStarted = (): SaveViewStartedAction => ({
  type: 'SAVE_VIEW_STARTED'
});

export interface SaveViewSucceededAction {
  type: 'SAVE_VIEW_SUCCEEDED';
  savedView: View;
}

const saveViewSucceeded = (savedView: View) => ({
  type: 'SAVE_VIEW_SUCCEEDED',
  savedView
});

export interface SaveViewFailedAction {
  type: 'SAVE_VIEW_FAILED';
}
const saveViewFailed = (): SaveViewFailedAction => ({
  type: 'SAVE_VIEW_FAILED'
});


export const setViewQueryString = (queryString: string): SetViewQueryString => ({ type: 'SET_VIEW_QUERY_STRING', queryString });

export const saveViewQueryString = (view: View, query: string, onSuccessCustom?: () => void, onErrorCustom?: () => void) => (dispatch: Dispatcher) => {
  const queryString = query;
  // until we have to deal with column metadata edits, let's let core handle creating the columns to go with our query
  const update = { queryString };

  const onSuccess = _.flowRight(dispatch, saveViewSucceeded);
  const onError = _.flowRight(dispatch, saveViewFailed);
  const clearSave = _.flowRight(dispatch, clearSaveStatus);

  dispatch(saveViewStarted());

  fetch(`/api/views/${view.id}.json`, {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT',
    body: JSON.stringify(update)
  }).then(checkStatus)
    .then(response => response.json())
    .then(response => {
      showSuccessToastNow(I18n.t('shared.components.asset_action_bar.save_success'));
      onSuccess(response);
      if (onSuccessCustom) onSuccessCustom();
    })
    .catch(err => {
      showErrorToastNow(I18n.t('shared.components.asset_action_bar.save_failed'));
      onError();
      if (onErrorCustom) onErrorCustom();
    })
    .then(clearSave); // if we start using the save/error state for anything, we'll want to debounce this
};

export const resumeAfterReconnect = (appState: AppState) => (dispatch: Dispatcher) => {
  if (appState.remoteStatusInfo.isEmpty) {
    // this doesn't look like a 'resume' situation
    return;
  }

  const rsi = appState.remoteStatusInfo.get;
  const query = appState.query;
  const successOpt = buildSuccessOption(appState.query);

  // this will also catch when a user has selected 'run' while disconnected from the websocket
  // since the first step there is 'compile'
  // when reconnected, they will need to prompt the query to run again
  // but they will not have lost any of their query work
  if (rsi.type === RemoteStatus.CompilingCandidateQuery) {
    query.compilationResult.forEach(r => {
      if (r.type === CompilationStatus.Started) {
        if (r.ast.nonEmpty) {
          // always resetting the page because its better than not doing it when we should have
          dispatch(compileAst(r.ast.get, true));
        } else if (r.text.nonEmpty) {
          dispatch(compileText(r.text.get));
        }
      }
    });
  }

  if (query.isQueryInProgress) {
    successOpt.forEach(compSuccess => {
      const successTab = rsi.type === RemoteStatus.RunningCompiledQuery ? rsi.successTab : none;
      dispatch(runQuery(appState.fourfourToQuery, compSuccess, storeUndo, successTab));
    });
  }

  if (rsi.type === RemoteStatus.CollocatingCompiledQuery) {
    successOpt.forEach(compSuccess => {
      dispatch(collocateAndRunQuery(appState.fourfourToQuery, compSuccess));
    });
  }
};

export interface ColumnUpdated {
  type: 'COLUMN_UPDATED';
  updatedColumn: VQEColumn;
}

export const ColumnUpdated = (updatedColumn: VQEColumn): ColumnUpdated => ({
  type: 'COLUMN_UPDATED',
  updatedColumn: updatedColumn
});

export interface ColumnsUpdated {
  type: 'COLUMNS_UPDATED';
  columns: VQEColumn[];
}

export const columnsUpdated = (columns: VQEColumn[]): ColumnsUpdated => ({
  type: 'COLUMNS_UPDATED',
  columns: columns
});

// Client Context Variable / Parameter Actions

export interface AddNewParameter {
  type: 'ADD_NEW_PARAMETER';
  parameter: ClientContextVariable;
}

export const AddNewParameter = (parameter: ClientContextVariable): AddNewParameter => ({
  type: 'ADD_NEW_PARAMETER',
  parameter
});

export interface ReplaceParameterList {
  type: 'REPLACE_PARAMETER_LIST';
  parameterList: ClientContextVariableCreate[];
  viewId: string;
}

// used when we've just saved a different set of parameters to working copy/revision
// need to merge the created ones with the inherited ones
export const ReplaceParameterList = (parameterList: ClientContextVariableCreate[], viewId: string): ReplaceParameterList => ({
  type: 'REPLACE_PARAMETER_LIST',
  parameterList,
  viewId
});

// Used to reset state to a previous version of parameters
// when saving to an external API is not in play
// such as 'undo' after changing an override
export interface ReplaceParameterOverrides {
  type: 'REPLACE_PARAMETER_OVERRIDES';
  parameterList: ClientContextVariable[];
}

export const replaceParameterOverrides = (parameterList: ClientContextVariable[]): ReplaceParameterOverrides => ({
  type: 'REPLACE_PARAMETER_OVERRIDES',
  parameterList
});

export interface ClearParameterOverrides {
  type: 'CLEAR_PARAMETER_OVERRIDES';
}

export const clearParameterOverrides = (): ClearParameterOverrides => ({
  type: 'CLEAR_PARAMETER_OVERRIDES'
});

export interface DeleteParameter {
  type: 'DELETE_PARAMETER';
  parameterName: string;
}

export const DeleteParameter = (parameterName: string): DeleteParameter => ({
  type: 'DELETE_PARAMETER',
  parameterName
});

export const editParameterOnWorkingCopy = (
  viewId: string,
  publishedViewId: string,
  parameter: ClientContextVariableCreate,
  onSuccessCustom?: (param: ClientContextVariableCreate) => void,
  onErrorCustom?: (err: any) => void
  ): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const onSuccess = (param: ClientContextVariableCreate) => {
    const appState = getState();
    dispatch(DeleteParameter(parameter.name));
    dispatch(AddNewParameter({...param, inherited: false, viewId: publishedViewId}));
    buildSuccessOption(appState.query).map(success => {
      dispatch(collocateAndRunQuery(appState.fourfourToQuery, success));
    });

    if (onSuccessCustom) {
      onSuccessCustom(param);
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };

  editClientContextVariable(viewId, parameter).then(onSuccess, onError);
};

export const createParameterOnWorkingCopy = (
  viewId: string,
  publishedViewId: string,
  parameter: ClientContextVariableCreate,
  onSuccessCustom?: (param: ClientContextVariableCreate) => void,
  onErrorCustom?: (err: any) => void
  ): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const onSuccess = (param: ClientContextVariableCreate) => {
    dispatch(AddNewParameter({...param, inherited: false, viewId: publishedViewId}));
    const appState = getState();
    appState.query.text.map(queryText => {
      storeUndo(appState, dispatch, queryText);
    });
    if (onSuccessCustom) {
      onSuccessCustom(param);
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };

  createClientContextVariable(viewId, parameter).then(onSuccess, onError);
};

export const deleteParameterOnWorkingCopy = (
  viewId: string,
  parameterName: string,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const onSuccess = () => {
    dispatch(DeleteParameter(parameterName));
    const appState = getState();
    appState.query.text.map(queryText => {
      storeUndo(appState, dispatch, queryText);
    });
    if (onSuccessCustom) {
      onSuccessCustom();
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };

  deleteClientContextVariable(viewId, parameterName).then(
    onSuccess,
    onError
  );
};

export const replaceAllParametersOnWorkingCopy = (
  viewId: string,
  parameters: ClientContextVariableCreate[],
  considerUndoable = true,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const onSuccess = () => {
    const appState = getState();
    const publishedViewId = option(appState.view.publishedViewUid);
    dispatch(ReplaceParameterList(parameters, publishedViewId.getOrElseValue(viewId)));
    if (considerUndoable) {
      appState.query.text.map(queryText => {
        storeUndo(appState, dispatch, queryText);
      });
    }
    if (onSuccessCustom) {
      onSuccessCustom();
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };

  replaceClientContextVariablesOnView(viewId, parameters).then(
    onSuccess,
    onError
  );
};

export const createParameterOnRevision = (
  viewId: string,
  parameter: ClientContextVariableCreate,
  revisionSeq: number,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState ) => {
  const existingVars = getState().clientContextInfo.variables.filter(v => !v.inherited).map(param => {
    const createVersion: ClientContextVariableCreate = {
      name: param.name,
      displayName: param.displayName,
      dataType: param.dataType,
      defaultValue: param.defaultValue,
      suggestedValuesType: param.suggestedValuesType,
      suggestedValues: param.suggestedValues
    };
    return createVersion;
  });
  existingVars.push(parameter);
  dispatch(replaceParameterList(viewId, revisionSeq, existingVars, true, onSuccessCustom, onErrorCustom));
};

export const editParameterOnRevision = (
  viewId: string,
  parameter: ClientContextVariableCreate,
  revisionSeq: number,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState ) => {
  const editedVars = getState().clientContextInfo.variables.filter(v => !v.inherited).map(param => {
    if (param.name === parameter.name) {
      return parameter;
    } else {
      const createVersion: ClientContextVariableCreate = {
        name: param.name!,
        displayName: param.displayName,
        dataType: param.dataType,
        defaultValue: param.defaultValue!,
        suggestedValuesType: param.suggestedValuesType,
        suggestedValues: param.suggestedValues
      };
      return createVersion;
    }
  });

  const onSuccess = () => {
    const appState = getState();
    buildSuccessOption(appState.query).map(success => {
      dispatch(collocateAndRunQuery(appState.fourfourToQuery, success));
    });

    if (onSuccessCustom) {
      onSuccessCustom();
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };

  dispatch(replaceParameterList(viewId, revisionSeq, editedVars, true, onSuccess, onError));
};

export const deleteParameterOnRevision = (
  viewId: string,
  parameterName: string,
  revisionSeq: number,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const editedVars = getState().clientContextInfo.variables.filter(v => !v.inherited).filter(param => param.name !== parameterName)
  .map(param => {
    const createVersion: ClientContextVariableCreate = {
      name: param.name!,
      displayName: param.displayName,
      dataType: param.dataType,
      defaultValue: param.defaultValue!,
      suggestedValuesType: param.suggestedValuesType,
      suggestedValues: param.suggestedValues
    };
    return createVersion;
  });
  dispatch(replaceParameterList(viewId, revisionSeq, editedVars, true, onSuccessCustom, onErrorCustom));
};

export const replaceParameterList = (
  viewId: string,
  revisionSeq: number,
  newParameters: ClientContextVariableCreate[],
  considerUndoable = true,
  onSuccessCustom?: () => void,
  onErrorCustom?: (err: any) => void
): ActionThunk => (dispatch: Dispatcher, getState: CurrentState) => {
  const onSuccess = () => {
    dispatch(ReplaceParameterList(newParameters, viewId));
    if (considerUndoable) {
      const appState = getState();
      appState.query.text.map(queryText => {
        storeUndo(appState, dispatch, queryText);
      });
    }
    if (onSuccessCustom) {
      onSuccessCustom();
    }
  };
  const onError = (err: any) => {
    if (onErrorCustom) {
      onErrorCustom(err);
    }
  };
  replaceClientContextVariablesOnRevision(viewId, revisionSeq, newParameters).then(
    onSuccess,
    onError
  );
};

export function locationToUrl(getState: CurrentState, params: LocationParams) {
  const loc = params.queryString.match({
    some: (qs) => (
        `/query/${fixedEncodeURIComponent(qs)}${currentTab(params)}?${params.queryParams.toString()}`
    ),
    none: () => (
      ''
    )
  });

  const newLocation =  `${getState().baseLocation}${loc}`;
  if (window.location.origin.length + newLocation.length < MAX_FRONTEND_URL_SIZE) {
    return newLocation;
  } else {
    return `${getState().baseLocation}?${params.queryParams.toString()}`;
  }
}

function currentTab(params: LocationParams) {
  const { tab, subTab } = params;
  const subTabVal = subTab.getOrElseValue('');
  let tabString = '';
  if (tab) {
    tabString = `/page/${tab}`;

    if (subTabVal.length > 0) {
      tabString = `${tabString}/${subTabVal}`;
    }
  }
  return tabString;
}
