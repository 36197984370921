import withWindowSize, { DimensionProps } from 'common/components/hocs/withWindowSize';
import ResizePanel from 'common/components/ResizePanel';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { ExternalWindowPortal } from 'common/components/UndockableComponent';
import { currentUserIsLoggedIn } from 'common/current_user';
import { FeatureFlags } from 'common/feature_flags';
import { fetchTranslation } from 'common/locale';
import { CompilationStatus, QueryAnalysisSucceeded, QueryCompilationSucceeded } from 'common/types/compiler';
import { AnalyzedSelectedExpression, NamedExpr, isColumnRef, Scope } from 'common/types/soql';
import { View, ViewRight } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import _ from 'lodash';
import { default as React, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { none, Option, some } from 'ts-option';
import { analysisSuccess, compilationSuccess, lastInChain, querySuccess } from '../lib/selectors';
import { explicitlyAliasAggregates, isCalculatedColumn, SelectionItem, selectionWithProvenanceFromQueryAnalysis } from '../lib/soql-helpers';
import { Reason as UnappliedChangesModalReason } from './UnappliedChangesModalWrapper';
import {
  applyClicked,
  compileAndRunAst,
  compileText,
  Dispatcher,
  replaceParameterOverrides,
  setModalTargetWindow,
  setQueryText,
  undockEditor,
  updateTab
} from '../redux/actions';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import { AppState, ClientContextInfo, Query } from '../redux/store';
import { QueryStatus } from 'common/explore_grid/types';
import '../styles/grid-datasource.scss';
import CalculatedColModal from './CalculatedColModal';
import DatasourceClearAllExpr from './DatasourceClearAllExpr';
import DatasourceFooter from './DatasourceFooter';
import GridSoQLEditor from './GridSoQLEditor';
import ReformatButton from './ReformatButton';
import SearchView from './SearchView';
import TabLink from './TabLink';
import VisualColumnManager from './VisualColumnManager';
import VisualFilterEditor from './VisualFilterEditor';
import VisualGroupAggregateEditor from './VisualGroupAggregateEditor';
import VisualJoinEditor from './VisualJoinEditor';
import ParametersTable from './ParametersTable';
import AddParameterButton from './AddParameterButton';
import InsertParameterMenu from './InsertParameterMenu';
import { isDefaultView } from 'common/views/view_types';
import {
  ForgeToolbar,
  ForgeMiniDrawer,
  ForgeButton,
  ForgeIcon
} from '@tylertech/forge-react';
import { Tab } from 'common/explore_grid/types';
import { Either, left as buildLeft, right as buildRight } from 'common/either';
import { usingSoda3EC, whichAnalyzer } from '../lib/feature-flag-helpers';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_datasource');

interface ExtraProps {
  applyQuery: (tab: Option<Tab>) => void;
  discardChanges: () => void;
}
const deriveApplyDiscard = (
  query: Query,
  fourfour: string,
  scope: Scope,
  clientContextInfo: ClientContextInfo,
  applyQueryDisp: (fourfour: string, r: Either<QueryCompilationSucceeded, QueryAnalysisSucceeded>, scope: Scope, tab: Option<Tab>) => void,
  buildDiscardChangesDisp: (lastQueryText: Option<string>, lastClientContext: ClientContextInfo) => () => void
): ExtraProps => {
  const lastQueryText = querySuccess(query.queryResult).flatMap((qr) => qr.compiled.text);
  const discardChanges = buildDiscardChangesDisp(lastQueryText, clientContextInfo);

  // Applying the query does something if and only if the compiled text is different from the last-run text.
  if (usingSoda3EC()) {
    return analysisSuccess(query.analysisResult)
      .flatMap((ar) => {
        return querySuccess(query.queryResult)
          .filter((qr) => qr.analyzed.get.text !== ar.text || !_.isEmpty(clientContextInfo.pendingOverrides))
          .map((qr) => ({
            applyQuery: (tab: Option<Tab>) => applyQueryDisp(fourfour, buildRight(ar), scope, tab),
            discardChanges
          }));
      })
      .getOrElseValue({ applyQuery: _.noop, discardChanges });
  } else {
    return compilationSuccess(query.compilationResult)
      .flatMap((cr) => {
        return querySuccess(query.queryResult)
          .filter((qr) => qr.compiled.runnable !== cr.runnable || !_.isEmpty(clientContextInfo.pendingOverrides))
          .map((qr) => ({
            applyQuery: (tab: Option<Tab>) => applyQueryDisp(fourfour, buildLeft(cr), scope, tab),
            discardChanges
          }));
      })
      .getOrElseValue({ applyQuery: _.noop, discardChanges });
  }
};

interface StateProps {
  embedded: boolean;
  currentTab: Tab;
  fourfour: string;
  query: Query;
  scope: Scope;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  joinsEnabled: boolean;
  onDefaultView: boolean;
  undocked: boolean;
  modalTargetWindow: ReturnType<typeof window.open>; // hack to get around Window being modified globally
  canModifyParameters: boolean;
  clientContextInfo: ClientContextInfo;
}
interface DispatchProps {
  applyQuery: (fourfour: string, r: Either<QueryCompilationSucceeded, QueryAnalysisSucceeded>, scope: Scope, tab: Option<Tab>) => void;
  buildDiscardChanges: (lastQueryText: Option<string>, lastClientContext: ClientContextInfo) => () => void;
  setModalTargetWindow: (targetWindow: Window | null | undefined) => void;
  undockEditor: (undocked: boolean) => void;
  updateTab: (tab: Tab) => void;
}
export type MergedProps = StateProps & {
  applyQuery: (tab: Option<Tab>) => void;
  discardChanges: () => void;
  setModalTargetWindow: (targetWindow: Window | null | undefined) => void;
  undockEditor: (undocked: boolean) => void;
  updateTab: (tab: Tab) => void;
};

export interface State {
  isPaneOpen: boolean;
  successTab: Option<Tab>;
  needsResize: boolean;
  resizedHeight: Option<string>;
  excludedCalculatedColumns: Either<AnalyzedSelectedExpression[], SelectionItem[]>;
  isSoqlDocsOpen: boolean;
}

class GridDatasource extends React.Component<MergedProps & DimensionProps, State> {
  state = {
    isPaneOpen: false,
    successTab: none,
    needsResize: false,
    resizedHeight: none,
    excludedCalculatedColumns: whichAnalyzer<AnalyzedSelectedExpression[], SelectionItem[]>(() => [], () => [])(),
    isSoqlDocsOpen: false
  };

  isMobile = () => {
    return this.props.mediaSize === 'small';
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.closeDatasource);
  };

  currentTab = (): Tab => {
    const current = this.props.currentTab;
    // this is the somewhat strange case where we're in mobile-responsive
    // world and the search sidebar is on the bottom, and then the user
    // resizes their screen, we need to default to make the current tab *not*
    // search since search will move to the sidebar, so we will just default back
    // to filter
    // in the regular non-mobile case, the user has no way of getting to tab == 'search'
    // but if they fiddle with screen sizes it is a possible state
    if (!this.isMobile() && current === Tab.Search) {
      return Tab.Filter;
    }

    /* Default to the filter tab when the location at
     * the end of the URL is not recognized so that it is
     * consistent with where /explore first takes the user. */
    if (!_.includes(Tab, current)) {
      return Tab.Filter;
    }

    return current;
  };

  showFooter = (): boolean => {
    const tabs = [Tab.Filter, Tab.Aggregate, Tab.ColumnManager, Tab.Join, Tab.Parameters];
    return tabs.includes(this.currentTab());
  };

  renderHeader = () => {
    const currentTab = this.currentTab();
    const showUndockButton = currentTab === Tab.Code;
    const undockButton = showUndockButton && (
      <span className="datasource-header-button datasource-left-subtitle">
        <ForgeButton>
          <button onClick={() => this.props.undockEditor(!this.props.undocked)}>
            <ForgeIcon name="arrange_bring_forward" />
            <span className="undock-button-text">{t('header.undock')}</span>
          </button>
        </ForgeButton>
      </span>
    );

    // do not render the soql docs if: hide_soql_docs is set to true OR the user is unauthenticated
    const renderEmbeddedSoQLDocs =
      !FeatureFlags.valueOrDefault('hide_soql_docs', false) && currentUserIsLoggedIn();

    const soqlDocsButton = (
      <ForgeButton dense>
        <button aria-label={t('library')} onClick={this.toggleSoqlDocs}>
          <ForgeIcon name="search"></ForgeIcon>
          {t('library')}
        </button>
      </ForgeButton>
    );

    return (
      <ForgeToolbar className="datasource-header">
        <div slot="start">
          <span className="grid-datasource-header-toolbar ">
            <span className="forge-typography--title" data-testid="grid-datasource-header-title">
              {t(currentTab === Tab.Aggregate ? 'header.aggregate' : currentTab)}
            </span>
            <DatasourceClearAllExpr tab={currentTab} />
            <ReformatButton tab={currentTab} />
            <AddParameterButton canModifyParameters={this.props.canModifyParameters} tab={currentTab} />
            <InsertParameterMenu
              canModifyParameters={this.props.canModifyParameters}
              tab={currentTab}
              parameters={this.props.clientContextInfo.variables}
            />
            {undockButton}
          </span>
          <span>
            <a className="grid-datasource-close" onClick={this.closeDatasource}>
              <SocrataIcon name={IconName.Close2} />
            </a>
          </span>
        </div>
        {currentTab === Tab.Code && renderEmbeddedSoQLDocs && <div slot="end">{soqlDocsButton}</div>}
      </ForgeToolbar>
    );
  };

  renderContents = () => {
    const currentTab = this.currentTab();
    if (currentTab === Tab.Join) {
      return <VisualJoinEditor />;
    } else if (currentTab === Tab.Filter) {
      return <VisualFilterEditor />;
    } else if (currentTab === Tab.ColumnManager) {
      //  Eslint upgrade type error
      return <VisualColumnManager />;
    } else if (currentTab === Tab.Aggregate) {
      return <VisualGroupAggregateEditor />;
    } else if (currentTab === Tab.Search && this.isMobile()) {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      return <SearchView />;
    } else if (currentTab === Tab.Parameters) {
      return <ParametersTable canModifyParameters={this.props.canModifyParameters} />;
    } else {
      return (
        <GridSoQLEditor
          resizedHeight={this.state.resizedHeight}
          needsResize={this.state.needsResize}
          onResizeComplete={() => this.setState({ needsResize: false })}
          isSoqlDocsOpen={this.state.isSoqlDocsOpen}
          closeSoqlDocs={() => this.closeSoqlDocs()}
        />
      );
    }
  };

  closeDatasource = () => {
    this.setState({ isPaneOpen: false });
  };

  openDatasource = () => {
    this.setState({ isPaneOpen: true });
  };

  closeSoqlDocs = () => {
    this.setState({ isSoqlDocsOpen: false });
  };

  toggleSoqlDocs = () => {
    this.state.isSoqlDocsOpen
      ? this.setState({ isSoqlDocsOpen: false })
      : this.setState({ isSoqlDocsOpen: true });
  };

  onTabLinkClick =
    (
      to: Tab,
      openUnappliedChangesModal: (unappliedChangesModalReason: UnappliedChangesModalReason) => void,
      discardChanges: () => void
    ) =>
    (event: MouseEvent<HTMLAnchorElement>) => {
      const doit = () => {
        this.openDatasource();
        this.props.updateTab(to);
      };

      const shouldDiscardChanges =
        to != this.currentTab() &&
        SelectRemoteStatus.discardChangesOnTabChange(this.props.remoteStatusInfo).isDefined;

      const shouldOpenModal =
        to != this.currentTab() &&
        (SelectRemoteStatus.applyable(this.props.remoteStatusInfo).isDefined) &&
        (this.showFooter() || this.currentTab() === Tab.Code);

      const shouldOpenModalForCannotRunQuery =
        (this.currentTab() === Tab.Code || this.currentTab() === Tab.Parameters) &&
        to != this.currentTab() &&
        SelectRemoteStatus.cannotRunQuery(this.props.remoteStatusInfo).isDefined;

      const shouldOpenCompilingQuery =
        this.currentTab() === Tab.Code &&
        to != Tab.Code &&
        SelectRemoteStatus.compilingCandidateQuery(this.props.remoteStatusInfo).isDefined;

      if (shouldOpenModalForCannotRunQuery) {
        event.preventDefault();
        openUnappliedChangesModal(UnappliedChangesModalReason.INVALID_SOQL);
      } else if (shouldOpenCompilingQuery) {
        event.preventDefault();
        openUnappliedChangesModal(UnappliedChangesModalReason.COMPILING_QUERY);
      } else if (shouldDiscardChanges) {
        event.preventDefault();
        discardChanges();
      } else if (shouldOpenModal) {
        event.preventDefault();
        openUnappliedChangesModal(UnappliedChangesModalReason.TAB);
      } else {
        doit();
      }
    };

  excludedCalculatedColumns = (): AnalyzedSelectedExpression[] => {
    const { compilationResult, queryResult } = this.props.query;

    const { compiledSelection, viewColumns } = compilationResult
      .map((c) => {
        if (c.type === CompilationStatus.Succeeded) {
          return {
            compiledSelection: lastInChain(c.analyzed).selection,
            viewColumns: Object.values(c.views).reduce(
              (acc: ViewColumn[], curr: View) => [...acc, ...curr.columns],
              []
            )
          };
        }
        return { compiledSelection: [], viewColumns: [] };
      })
      .getOrElseValue({ compiledSelection: [], viewColumns: [] });
    const querySelection = queryResult
      .map((q) => (q.type === QueryStatus.QUERY_SUCCESS ? lastInChain(q.compiled.analyzed).selection : []))
      .getOrElseValue([]);

    return querySelection.reduce((acc: AnalyzedSelectedExpression[], curr: AnalyzedSelectedExpression) => {
      const inCompiled = compiledSelection.find((expr) => _.isEqual(expr.expr, curr.expr));
      // Case insensitive find for column fieldname
      const inViewColumns = viewColumns.find(
        (col) => isColumnRef(curr.expr) && col.fieldName === curr.expr.value.toLowerCase()
      );
      if (!(inCompiled || inViewColumns)) acc.push(curr);
      return acc;
    }, []);
  };
  excludedCalculatedColumnsNA = (): SelectionItem[] => {
    const { analysisResult, queryResult } = this.props.query;

    const newSelectionItems = analysisSuccess(analysisResult)
      .map(selectionWithProvenanceFromQueryAnalysis)
      .getOrElseValue([])
      .filter(isCalculatedColumn);
    const oldSelectionItems = querySuccess(queryResult)
      .flatMap(qr => qr.analyzed).map(selectionWithProvenanceFromQueryAnalysis)
      .getOrElseValue([])
      .filter(isCalculatedColumn);

    return oldSelectionItems.filter(osi => {
      const exprPreviouslyPresent = newSelectionItems.some(nsi => _.isEqual(nsi.expr, osi.expr));
      const namePreviouslyPresent = newSelectionItems.some(nsi => _.isEqual(nsi.schemaEntry.name, osi.schemaEntry.name));
      const notExcluded = exprPreviouslyPresent || namePreviouslyPresent;
      return !notExcluded;
    });
  };

  onApplyQuery = () => {
    const currentTab = this.currentTab();
    if (currentTab === Tab.ColumnManager || currentTab === Tab.Aggregate) {
      // If we're on the column manager or group/aggregate panes, we check for
      // excluded calculated columns, otherwise we just apply.
      const excludedCalculatedColumns = whichAnalyzer(
        this.excludedCalculatedColumns,
        this.excludedCalculatedColumnsNA
      )();
      if (excludedCalculatedColumns.get.length > 0) {
        this.setState({ excludedCalculatedColumns });
        return;
      }
    }
    this.props.applyQuery(some(this.currentTab()));
  };

  onDismissCalcColModal = () => {
    const excludedCalculatedColumns = whichAnalyzer<AnalyzedSelectedExpression[], SelectionItem[]>(() => [], () => [])();
    this.props.discardChanges();
    this.setState({ excludedCalculatedColumns });
  };

  onApplyCalcColModal = () => {
    const excludedCalculatedColumns = whichAnalyzer<AnalyzedSelectedExpression[], SelectionItem[]>(() => [], () => [])();
    this.setState({ excludedCalculatedColumns });
    this.props.applyQuery(some(this.currentTab()));
  };

  render() {
    const { discardChanges, remoteStatusInfo, mediaSize, onDefaultView, joinsEnabled } =
      this.props;
    const { isPaneOpen, successTab } = this.state;
    const currentTab = this.currentTab();
    const selectedClass = (tab: Tab) => {
      const visible = this.isMobile() ? isPaneOpen : true;
      return visible && tab === currentTab ? 'current' : '';
    };
    const tabLinkProps = {
      discardChanges,
      selectedClass,
      targetWindow: this.props.modalTargetWindow!,
      onApplyQuery: this.onApplyQuery,
      updateTab: this.props.updateTab,
      onClick: this.onTabLinkClick
    };
    const datasourceFooterProps = {
      currentTab,
      remoteStatusInfo,
      successTab,
      onApplyQuery: this.onApplyQuery
    };

    // The search tab was "temporarily" removed in EN-46190, now that there is no tab bar in mobile view changing this to a constant for linting purposes
    const search = null;

    const renderParameters = !onDefaultView;
    // do not render joins if:
    //   * the joins module is not enabled OR
    //   * the user is unauthenticated
    const renderJoins = joinsEnabled && currentUserIsLoggedIn();
    // do not render the soql editor if:
    //   * hide_soql_tab feature flag is set to true OR
    //   * the user is unauthenticated
    const renderSoQL = !FeatureFlags.valueOrDefault('hide_soql_tab', false) && currentUserIsLoggedIn();

    const tabs = !this.isMobile() ? (
      <ForgeMiniDrawer type="mini" className="grid-datasource-tabs" role="tablist">
        <TabLink {...tabLinkProps} toTab={Tab.Filter} />
        <TabLink {...tabLinkProps} toTab={Tab.Aggregate} />
        <TabLink {...tabLinkProps} toTab={Tab.ColumnManager} />
        {renderJoins && <TabLink {...tabLinkProps} toTab={Tab.Join} />}
        {renderParameters && <TabLink {...tabLinkProps} toTab={Tab.Parameters} />}
        {renderSoQL && <TabLink {...tabLinkProps} className="advanced-tab" toTab={Tab.Code} />}
        {search}
      </ForgeMiniDrawer>
    ) : (
      ''
    );

    const body = (
      <div className="grid-datasource-body" id="grid-datasource-tabpanel">
        {this.renderHeader()}
        {this.renderContents()}
        {this.showFooter() && <DatasourceFooter {...datasourceFooterProps} />}
        {(currentTab === Tab.ColumnManager || currentTab === Tab.Aggregate) && (
          <CalculatedColModal
            onApply={this.onApplyCalcColModal}
            onDismiss={this.onDismissCalcColModal}
            excludedColumns={this.state.excludedCalculatedColumns.get}
            tab={currentTab}
          />
        )}
      </div>
    );

    const wrappedBody = (
      <div className={`grid-datasource ${isPaneOpen ? 'open' : ''}`}>
        {tabs}
        {body}
      </div>
    );

    if (this.props.undocked) {
      // Replicate the DOM structure as much as possible.
      return (
        <div id="explore-grid-app">
          <div className="undocked-grid-datasource">
            <div className="grid-wrap">
              <div className="grid-main">{body}</div>
            </div>
          </div>
        </div>
      );
    } else if (mediaSize !== 'small') {
      // on regular devices, this whole thing is resizable
      return (
        <ResizePanel
          direction="n"
          minInitialSize={346}
          borderClass="grid-custom-resize-border"
          onResize={(height: number) => {
            this.setState({ needsResize: true, resizedHeight: some(`${height}px`) });
          }}
        >
          {wrappedBody}
        </ResizePanel>
      );
    } else {
      // on mobile devices, it's 100% of the height
      return wrappedBody;
    }
  }
}

interface ExternalProps {
  embedded: boolean; // This isn't actually used yet, but I'm leaving it because I suspect we'll need it.
}
const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
  currentTab: state.locationParams.tab,
  fourfour: state.fourfourToQuery,
  query: state.query,
  remoteStatusInfo: state.remoteStatusInfo,
  scope: state.scope.getOrElseValue([]),
  joinsEnabled: state.joinsEnabled,
  onDefaultView: isDefaultView(state.view),
  undocked: state.undocked,
  modalTargetWindow: state.modalTargetWindow,
  embedded: externalProps.embedded,
  canModifyParameters: state.editing && state.view.rights.includes(ViewRight.UpdateColumn),
  clientContextInfo: state.clientContextInfo
});

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    applyQuery: (fourfour: string, result: Either<QueryCompilationSucceeded, QueryAnalysisSucceeded>, scope: Scope, tab: Option<Tab>) => {
      result.fold(
        (r) => {
          /* If the user makes any changes on the group tab, all aggregates are explicitly aliased. */
          tab.forEach((current) => current === Tab.Aggregate && explicitlyAliasAggregates(scope, r));
          dispatch(compileAndRunAst(fourfour, r.unanalyzed, none, tab));
        },
        (r) => {
          // Don't need to explicitly alias the aggregates in new analyzer.
          dispatch(compileAndRunAst(fourfour, r.ast, none, tab));
        }
      );
      dispatch(applyClicked(new Date()));
    },
    buildDiscardChanges: (lastQueryText: Option<string>, lastClientContext: ClientContextInfo) => () => {
      lastQueryText.map((text) => {
        dispatch(replaceParameterOverrides(lastClientContext.variables));
        dispatch(setQueryText(text));
        dispatch(compileText(text));
      });
    },
    setModalTargetWindow: (tWindow: Window | null | undefined) => {
      dispatch(setModalTargetWindow(tWindow));
    },
    undockEditor: (undocked: boolean) => {
      dispatch(undockEditor(undocked));
    },
    updateTab: (tab: Tab) => dispatch(updateTab(tab))
  };
};

export function mergeProps<TStateProps = any, TDispatchProps = any>(
  state: StateProps & TStateProps,
  disp: DispatchProps & TDispatchProps
): MergedProps {
  const { applyQuery, discardChanges } = deriveApplyDiscard(
    state.query,
    state.fourfour,
    state.scope,
    state.clientContextInfo,
    disp.applyQuery,
    disp.buildDiscardChanges
  );
  return {
    ...state,
    applyQuery,
    discardChanges,
    undockEditor: disp.undockEditor,
    modalTargetWindow: state.modalTargetWindow,
    setModalTargetWindow: disp.setModalTargetWindow,
    remoteStatusInfo: state.remoteStatusInfo,
    updateTab: disp.updateTab
  };
}

interface UndockableGridDatasourceState {
  isHidden: boolean;
  isSoqlDocsOpen: boolean;
}

class UndockableGridDatasource extends React.Component<
  MergedProps & DimensionProps,
  UndockableGridDatasourceState
> {
  state: UndockableGridDatasourceState = {
    isHidden: document.hidden,
    isSoqlDocsOpen: false
  };

  constructor(props: MergedProps & DimensionProps) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange() {
    this.setState({ isHidden: document.hidden });
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  }

  closeSoqlDocs = () => {
    this.setState({ isSoqlDocsOpen: false });
  };

  toggleSoqlDocs = () => {
    this.state.isSoqlDocsOpen
      ? this.setState({ isSoqlDocsOpen: false })
      : this.setState({ isSoqlDocsOpen: true });
  };

  render() {
    const portalProps = {
      windowName: 'undockable-grid-soql-editor',
      setCustomTargetWindow: this.props.setModalTargetWindow,
      onClose: () => this.props.undockEditor(false),

      position: {
        top: window.screenTop + Math.round(document.body.clientHeight * 0.35),
        left: window.screenLeft + Math.round(document.body.clientWidth * 0.05)
      },
      size: {
        width: Math.round(document.body.clientWidth * 0.95),
        height: Math.round(document.body.clientHeight * 0.6)
      }
    };

    const renderEmbeddedSoQLDocs =
      !FeatureFlags.valueOrDefault('hide_soql_docs', false) && currentUserIsLoggedIn();

    if (this.props.undocked) {
      return (
        <ExternalWindowPortal {...portalProps}>
          <div id="explore-grid-app">
            <div className="undocked-grid-datasource">
              <div className="grid-wrap">
                <div className="grid-main">
                  <div className="grid-datasource">
                    <div className="grid-datasource-body" id="grid-datasource-tabpanel">
                      <ForgeToolbar className="datasource-header">
                        <div slot="start">
                          <span>
                            <h4>{t(Tab.Code)}</h4>
                            <ReformatButton tab={Tab.Code} />
                            <InsertParameterMenu
                              canModifyParameters={this.props.canModifyParameters}
                              tab={Tab.Code}
                              parameters={this.props.clientContextInfo.variables}
                            />
                            <span className="datasource-header-button datasource-left-subtitle">
                              <ForgeButton>
                                <button onClick={() => this.props.undockEditor(!this.props.undocked)}>
                                  <ForgeIcon name="dock_bottom" />
                                  <span className="undock-button-text">{t('header.dock_to_bottom')}</span>
                                </button>
                              </ForgeButton>
                            </span>
                          </span>
                        </div>
                        {renderEmbeddedSoQLDocs && (
                          <div slot="end">
                            <ForgeButton dense>
                              <button aria-label={t('library')} onClick={this.toggleSoqlDocs}>
                                <ForgeIcon name="search"></ForgeIcon>
                                {t('library')}
                              </button>
                            </ForgeButton>
                          </div>
                        )}
                      </ForgeToolbar>
                      <GridSoQLEditor
                        isParentHidden={this.state.isHidden}
                        resizedHeight={some('100%')}
                        isSoqlDocsOpen={this.state.isSoqlDocsOpen}
                        closeSoqlDocs={() => this.closeSoqlDocs()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ExternalWindowPortal>
      );
    } else {
      return <GridDatasource {...this.props} />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withWindowSize<MergedProps>(UndockableGridDatasource));
