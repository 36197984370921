import {
  ICellRendererParams,
  ITooltipParams,
  ColDef,
  IServerSideGetRowsParams,
  SortDirection
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { LicenseManager as AGLicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { getCurrentDomain } from 'common/currentDomain';
import { formatDateWithLocale } from 'common/dates';
import { formatForDisplay } from 'common/formatBoolean';
import {
  SoQLType,
  AnalyzedSelectedExpression,
  OrderBy,
  UnAnalyzedSelectedExpression,
  isExpressionEqualIgnoringPosition,
  isColumnRef,
  isGeospatial
} from 'common/types/soql';
import React from 'react';
import { option, none, Option, some } from 'ts-option';
import { getTableAliases, lastInChain, querySuccess, viewContextFromQuery } from '../lib/selectors';
import { zipSelection } from '../lib/soql-helpers';
import { Query, QueryMetaSuccess, QuerySuccess, VQEColumn } from '../redux/store';
import { extractSelectionForColumnNames } from 'common/soql/binary-tree';
import DataTypeFormatter from 'common/DataTypeFormatter';
import * as VisualContainer from './visualContainer';
import _ from 'lodash';

// It's ok for this license key to be exposed (https://www.ag-grid.com/javascript-data-grid/licensing/#setting-the-license-key)
AGLicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-073142}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tyler_Technologies}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Socrata}_only_for_{38}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Socrata}_need_to_be_licensed___{Socrata}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_July_2026}____[v3]_[01]_MTc4Mzk4MzYwMDAwMA==953dd63b704319b712726e8a48b3898f'
);

export interface EcAgTableProps {
  result: QuerySuccess;
  runAST: VisualContainer.RunAST;
  query: Query;
  columns: VQEColumn[];
  fourfour: string;
}

// NOTE: This is a WORK IN PROGRESS, it is not ready to be used by customers. This should all be behind enable_explore_grid_table_upgrade
// Here is the epic that is tracking all the work here EN-68319
const EcAgTable = (props: EcAgTableProps) => {
  const analyzedSelection = extractSelectionForColumnNames(props.result.compiled.analyzed);
  const tableAliases = getTableAliases(props.query);
  const viewContext = viewContextFromQuery(props.query);
  const unanalyzed = lastInChain(props.result.compiled.unanalyzed);
  const unanalyzedSelection = viewContext.map((vc) => {
    return zipSelection(vc, tableAliases, unanalyzed.selection, analyzedSelection);
  });

  const domain = getCurrentDomain();

  const agGridModules = [ServerSideRowModelModule, MenuModule, ClipboardModule];

  // ################################## Helper functions ########################################

  const getVQEColumnCaseInsensitive = (columns: VQEColumn[], selection: AnalyzedSelectedExpression) =>
    columns.find((col) => col.fieldName === selection.name.toLowerCase());

  const getMeta = (): Option<QueryMetaSuccess> => {
    return querySuccess(props.query.queryResult)
      .map((qs) => qs.meta)
      .flatMap((meta) => {
        if (meta.type === 'query_meta_success') {
          return some(meta);
        }
        return none;
      });
  };

  const getCount = (): number | undefined => {
    return getMeta().match({
      none: () => undefined,
      some: (meta) => meta.rowCount
    });
  };

  // ################################## Cell formatting ########################################

  const agGridDataFormatter = (params: ICellRendererParams) => {
    const columnDef = params.colDef?.cellRendererParams.column;
    return DataTypeFormatter.renderCellHTML(
      params.value,
      // note this is designed to work with ViewColumn... but VQEColumn appears to compatible with the addition of renderTypeName
      { ...columnDef, renderTypeName: columnDef.dataTypeName },
      domain,
      props.fourfour
    );
  };

  const cellValueRenderer = (params: ICellRendererParams) => {
    const formattedValue = agGridDataFormatter(params);
    const renderTextAsHtml = (text: string) => {
      return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
    };

    return renderTextAsHtml(formattedValue);
  };

  // ################################## Tooltips ########################################
  // ToDo this chunk of logic will need reworked to handle custom column formatting as well as data type formatting
  // This logic should be able to be reused for column, tooltip and clipboard formatting
  // Tracked on this ticket EN-68323
  const getBlobUrl = (cellContent: string) => {
    if (!cellContent) {
      return '';
    }
    return `https://${domain}/views/${props.fourfour}/files/${cellContent}`;
  };

  const dateFormatter = (v: ITooltipParams<any, any, any>) => {
    if (!v.value) return null;

    return formatDateWithLocale(v.value, true);
  };

  const tooltipValueGetterFunction = (toolTipParams: ITooltipParams) => {
    const displayType = toolTipParams.colDef?.tooltipComponentParams.column.dataTypeName;
    let formattedValue = null;
    switch (displayType) {
      case SoQLType.SoQLFixedTimestampAltT:
      case SoQLType.SoQLFixedTimestampT:
      case SoQLType.SoQLFloatingTimestampAltT:
      case SoQLType.SoQLFloatingTimestampT:
        formattedValue = dateFormatter(toolTipParams);
        break;
      case SoQLType.SoQLBooleanT:
        formattedValue = formatForDisplay('default', toolTipParams.value);
        break;
      case SoQLType.SoQLURLT:
        formattedValue = toolTipParams.value?.url;
        break;
      case SoQLType.SoQLPointT:
      case SoQLType.SoQLLineT:
      case SoQLType.SoQLPolygonT:
      case SoQLType.SoQLMultiLineT:
      case SoQLType.SoQLMultiPointT:
      case SoQLType.SoQLMultiPolygonT:
        formattedValue = DataTypeFormatter.renderWKTCellHTML(toolTipParams.value);
        break;
      case 'blob':
        formattedValue = getBlobUrl(toolTipParams.value);
        break;
      //ToDo EN-68323 addresses column formatting - these exotic types need to be implemented tested, I believe you might be able to use various renderCellHTML functions in common/DataTypeFormatter.js
      // case 'photo':
      //   formattedValue = renderPhotoCellHTML(cellContent, domain, datasetUid);
      //   break;
      // case 'json':
      //   formattedValue = renderJsonCell(cellContent);
      //   break;
      // case 'document':
      //   formattedValue = renderDocumentCellHTML(cellContent, domain, datasetUid);
      //   break;

      case SoQLType.SoQLLocationT:
        formattedValue = toolTipParams.value;
        break;
      default:
        formattedValue = toolTipParams.value;
    }
    return formattedValue;
  };

  // ################################## Sort/ordering ########################################
  // ToDo loading sort and implementing sort will be done on this ticket EN-68320
  const getAgColumnSort = (soqlOrderBy: Option<OrderBy>) => {
    const orderBys = unanalyzed.order_bys;

    let columnIsSorted = false;
    let columnSort: SortDirection | undefined = undefined;
    let sortIndex = null;
    // prob need to do something like this as index is not being set... do we need it?
    //const orderIndex = orders.findIndex((o: OrderConfig) => column.fieldName === o.columnName);

    soqlOrderBy.match({
      none: () => {},
      some: (orderBy) => {
        columnIsSorted = true;
        columnSort = orderBy.ascending ? 'asc' : 'desc';
        sortIndex = null;
      }
    });

    return { columnIsSorted, columnSort, sortIndex };
  };

  const getExistingSoqlOrderby = (
    unanalyzedSelectedExpr: Option<UnAnalyzedSelectedExpression>,
    selection: AnalyzedSelectedExpression
  ) => {
    return unanalyzedSelectedExpr.flatMap((selectedExpr) => {
      return option(
        unanalyzed.order_bys.find(
          (ob) =>
            // this finds order bys that are just exprs, like `select foo + 1 order by foo + 1`
            isExpressionEqualIgnoringPosition(ob.expr, selectedExpr.expr) ||
            // this finds order bys that refer to aliases, like `select foo + 1 as whatever order by whatever`
            (isColumnRef(ob.expr) && ob.expr.value === selection.name)
        )
      );
    });
  };

  // TODO okay, this doesn't currently work that well because it causes a rerender which loads the old sort back
  // this might be able to be fixed doing something like common/visualizations/views/agGridReact/customHooks/useColDefs.ts
  // tracked on this ticket EN-68320
  // const handleColumnSort = (sortEvent: SortChangedEvent) => {
  //   const api = sortEvent.api;
  //   // maybe I don't need this ??
  //   const soqlColumns = sortEvent.columns?.map(
  //     (agColumn) => agColumn.getColDef().context?.soqlOrderBy
  //   ) as Option<OrderBy>[];

  //   const columnState = api.getColumnState();

  //   // based on column state data, select only sorted columns
  //   const sortedColumns: ColumnState[] = columnState.filter((column) => column.sort !== null);
  //   // .sort mutates the array
  //   // This sorts the columns based on the sortIndex, organizing the columns in the multi sort order.
  //   sortedColumns.sort((colA: ColumnState, colB: ColumnState) => {
  //     const aSortIndex = colA?.sortIndex ?? 0;
  //     const bSortIndex = colB?.sortIndex ?? 0;

  //     return aSortIndex - bSortIndex;
  //   });

  //   // we need tp replace orderBys too
  //   const formattedColumns: OrderBy[] = sortedColumns.map((col) => ({
  //     ascending: col.sort === 'asc',
  //     null_last: false, // TODO set this correctly
  //     expr: {
  //       value: col.colId,
  //       type: 'column_ref',
  //       qualifier: null // TODO might need to set something here???
  //     }
  //   }));

  //   props.runAST(
  //     {
  //       ...unanalyzed,
  //       order_bys: formattedColumns
  //     },
  //     none
  //   );
  // };

  // ################################## Table configurations ########################################

  const colDefs: ColDef[] = analyzedSelection.map((selection, i) => {
    const column = getVQEColumnCaseInsensitive(props.columns, selection);

    const unanalyzedSelectedExpr = unanalyzedSelection.flatMap((un) => option(un.exprs[i]));

    const soqlOrderBy = getExistingSoqlOrderby(unanalyzedSelectedExpr, selection);

    // I think there should always be a column, but you know type safety
    if (!column) return {};

    const { columnIsSorted, columnSort, sortIndex } = getAgColumnSort(soqlOrderBy);

    return {
      field: column.fieldName,
      headerName: column.name,
      headerTooltip: column.name,
      cellRendererParams: {
        datasetUid: props.fourfour,
        column: column,
        domain,
        wrapWithDivElement: true
      },
      cellRenderer: cellValueRenderer,
      tooltipComponentParams: {
        datasetUid: props.fourfour,
        column: column,
        domain,
        wrapWithDivElement: true
      },
      tooltipValueGetter: tooltipValueGetterFunction,
      // Sorting is being tracked here EN-68320
      sort: columnIsSorted ? columnSort : undefined,
      sortIndex, // not currently setting this - EN-68320 tracks this work
      // context??? => maybe we set the soqlOrderBy that is associated with this column here???
      //context: { soqlOrderBy, test: 'testme' },
      sortable: !isGeospatial(column.dataTypeName)
      // You can add sort here see //common/visualizations/views/agGridReact/helpers/computeAgColumn.ts
    };
  });

  // this is what ag-grid will use to set the displayed data
  const datasource = {
    getRows: async (params: IServerSideGetRowsParams) => {
      const { request, api } = params;

      try {
        const datasetRows = props.result.rows;

        return params.success({
          rowData: datasetRows,
          rowCount: getCount()
        });
      } catch (e) {
        return;
      }
    }
  };

  // ################################## Table ########################################
  const contextMenuOptions = ['copy', 'copyWithHeaders'];

  return (
    <div
      data-testid="ec-ag-grid-table"
      className={'ag-theme-material'}
      style={{ width: '100%', height: '100%' }}
    >
      <AgGridReact
        columnDefs={colDefs}
        rowModelType={'serverSide'}
        modules={agGridModules}
        headerHeight={48}
        tooltipShowDelay={2}
        // TODO this is not currently working correctly so new sorting is not able to be applied - sorting being addressed on EN-68320
        //onSortChanged={handleColumnSort}
        serverSideDatasource={datasource}
        paginationPageSize={100} // this will be the default - matches old ec
        paginationPageSizeSelector={false} // right now the ast stuff is designed to work with page numbers, so if we rework that we can add this back in - open UX question
        pagination={true}
        suppressPaginationPanel={true} // right now the controls for pagination are outside the table
        multiSortKey={'ctrl'}
        loading={props.query.isQueryInProgress || props.query.queryResult.isEmpty}
        suppressContextMenu={false}
        allowContextMenuWithControlKey={true}
        getContextMenuItems={() => contextMenuOptions}
      />
    </div>
  );
};

export default EcAgTable;
