import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';
import DraftTableRow from 'datasetManagementUI/containers/DraftTableRowContainer';
import ViewTableRow from 'datasetManagementUI/containers/ViewTableRowContainer';

const scope = 'dataset_management_ui.dataset_contents';

export default function RowDetails({ editMode, changedData, draftRowLabel, ...params }) {
  return (
    <section className="landing-page-section dataset-contents" id="row-details">
      <h2 className="landing-page-section-header">{I18n.t('title', { scope })}</h2>
      <div className="section-content">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{I18n.t('rows', { scope })}</th>
              <th>{I18n.t('columns', { scope })}</th>
              <th>{I18n.t('row_display_unit', { scope })}</th>
              {params.rowIdentifier && <th>{I18n.t('row_identifier', { scope })}</th>}
            </tr>
          </thead>
          <tbody>
            {editMode && <ViewTableRow {...params} />}
            {changedData ? (
              <DraftTableRow {...params} />
            ) : (
              <tr>
                <td className="row-title">{I18n.t('draft', { scope })}</td>
                <td className="draft">{I18n.t('no_new_data', { scope })}</td>
                <td></td>
                <td>{draftRowLabel}</td>
                {params.rowIdentifier && <td>{params.rowIdentifier}</td>}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
}

RowDetails.propTypes = {
  editMode: PropTypes.bool,
  fourfour: PropTypes.string.isRequired,
  revisionSeq: PropTypes.number.isRequired,
  changedData: PropTypes.bool,
  draftRowLabel: PropTypes.string
};
