import React from 'react';
import { connect } from 'react-redux';

import { soqlRendering } from 'common/types/soql';
import I18n from 'common/i18n';

import { AppState, Query } from '../redux/store';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';
import { analysisSuccess, compilationSuccess } from '../lib/selectors';
import { Tab } from 'common/explore_grid/types';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeIcon } from '@tylertech/forge-react';
import { usingSoda3EC } from '../lib/feature-flag-helpers';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.grid_datasource' });

interface ExternalProps {
  tab: Tab;
}
interface StateProps {
  query: Query;
}
interface DispatchProps {
  setQueryText: (q: string) => void;
}

interface State {
  disabled: boolean;
  lastStateWasSuccess: boolean
}

type Props = ExternalProps & StateProps & {
  reformat: () => void;
};

class ReformatButton extends React.Component<Props, State> {
  state = {
    disabled: true,
    lastStateWasSuccess: false
  };

  componentDidUpdate() {
    const currentCompilationSuccess = compilationSuccess(this.props.query.compilationResult);
    const currentAnalysisSuccess = analysisSuccess(this.props.query.analysisResult);
    const currentlySuccessful = currentCompilationSuccess.nonEmpty;
    // If we're going from a success state to a success state, don't change anything
    if (currentlySuccessful && this.state.lastStateWasSuccess) return;
    // If we're going from a non-success state to a non-success state, don't change anything
    if (!currentlySuccessful && !this.state.lastStateWasSuccess) return;

    const alreadyFormatted = () => {
      return this.props.query.text.map(queryText => {
        if (usingSoda3EC()) {
          return currentAnalysisSuccess.map(a => a.formattedText === queryText).getOrElseValue(true);
        } else {
          return soqlRendering.unwrap(currentCompilationSuccess.get.rendering) === queryText;
        }
      }).getOrElseValue(true);
    };
    // disabled if:
    // * compilation is not successful (compiling, failed) OR
    // * the outcome of formatting would be the same as the current text
    const newDisabled = !currentlySuccessful || alreadyFormatted();

    if (currentlySuccessful != this.state.lastStateWasSuccess || newDisabled != this.state.disabled) {
      this.setState({lastStateWasSuccess: currentlySuccessful, disabled: newDisabled});
    }
  }

  render() {
    const { tab, reformat } = this.props;
    if (tab === Tab.Code) {
      return (
        <span className="datasource-header-button datasource-left-subtitle datasource-refresh-format most-left-subtitle">
          <span className="bar">|</span>
          <ForgeButton>
            <button
              className='format-btn'
              disabled={this.state.disabled}
              onClick={() => {
                reformat();
                this.setState({disabled: true});
              }}>
                <ForgeIcon name="refresh" />
                <span className="refresh-format-text">
                  {t('reformat')}
                </span>
            </button>
          </ForgeButton>
        </span>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    query: state.query,
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    setQueryText: (q) => {
      dispatch(Actions.setQueryText(q));
    }
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ExternalProps) => {
  return {
    ...ownProps,
    ...stateProps,
    reformat: () => {
      if (usingSoda3EC()) {
        analysisSuccess(stateProps.query.analysisResult).map(cr => {
          dispatchProps.setQueryText(cr.formattedText);
        });
      } else {
        compilationSuccess(stateProps.query.compilationResult).map(cr => {
          const formattedQuery = soqlRendering.unwrap(cr.rendering);
          dispatchProps.setQueryText(formattedQuery);
        });
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReformatButton);
