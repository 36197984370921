import _ from 'lodash';
import React from 'react';
import { Either, left as buildLeft, right as buildRight, factorOption } from 'common/either';
import { validateColumnFieldName } from 'common/column/utils';
import { fetchTranslation } from 'common/locale';
import {
  Expr,
  FunCall,
  NoPosition,
  Scope,
  SoQLFunCall,
  SoQLType,
  TypedSelect,
  UnAnalyzedSelectedExpression,
  UnAnalyzedAst,
  nullLiteral,
  NamedExpr,
  TypedExpr,
  FunSpec
} from 'common/types/soql';
import { ProjectionInfo, ProjectionInfoNA, ViewColumnColumnRef, getOnlyAggregates } from '../../lib/selectors';
import { ColumnType, isQueryColumn, matchPicked, PickableColumn, ProjectionExpr, Selected } from '../../lib/column-picker-helpers';
import { existingFieldNames, hasGroupOrAggregate, toTyped } from '../../lib/soql-helpers';
import { whichAnalyzer } from '../../lib/feature-flag-helpers';
import { none, Option, option, some } from 'ts-option';
import AggregateFunPicker from './AggregateFunPicker';
import { CompileAST } from '../visualContainer';
import ExpressionEditor from '../VisualExpressionEditor';
import { StatefulEdit } from '../visualNodes/EditLiteral';
import ColumnPicker from '../ColumnPicker';
import RemoveNode from '../RemoveNode';
import KebabMenu from '../../components/visualNodes/KebabMenu';
import { ClientContextVariable } from 'common/types/clientContextVariable';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_aggregates');

interface ValidationErrorProps {
  error: string;
}

const ValidationError = ({ error }: ValidationErrorProps) => (<div className="validation-error">{error}</div>);

interface EditAggregateNameProps {
  value: string;
  onChange: (n: string) => void;
  errors: string[];
}

export function EditAggregateName(props: EditAggregateNameProps) {
  return (
    <div className="aggregate-name">
      {t('api_field_name')}: <StatefulEdit {...props} className="edit-aggregate-name" label={t('api_field_name')} />
      {props.errors.map((err, index) => <ValidationError key={index} error={err} />)}
    </div>
  );
}

type FieldName = Option<string>;

// Find the available scope based on chosen column.
const findAvailableScope = (picked: PickableColumn, scope: Scope): Scope => {
  const soqlType = (isQueryColumn(picked)) ? picked.column.typedExpr.soql_type as SoQLType : picked.column.typedRef.soql_type as SoQLType;
  return scope.filter(fs => {
    const constraints = _.flatMap(fs.constraints.a || []); // TODO: EN-44287
    return _.isEmpty(constraints) || constraints.includes(soqlType);
  });
};

// Find the constraints based on the funcall.
const findConstraints = (funspec: FunSpec, scope: Scope): SoQLType[] => {
  if (!scope.includes(funspec)) { return []; } // aggregates only club
  return _.flatMap(funspec.constraints.a || []); // TODO: EN-44287
};

const toSelected = (column: Option<PickableColumn>): Option<Selected> => (
  column.map(picked => (picked.column))
);

export interface Props {
  ast: Either<UnAnalyzedAst, TypedSelect>;
  scope: Scope;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  compileAST: CompileAST;
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
  onHideAggregateAddExpr: () => void;
}

export interface State {
  column: Option<PickableColumn>;
  funspec: Option<FunSpec>;
  name: FieldName;
  errors: string[];
}

export default class AggregateAddExpr extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      column: none,
      funspec: none,
      name: none,
      errors: []
    };
  }

  attemptAddAggregate = (column: Option<PickableColumn>, funspec: Option<FunSpec>, name: FieldName) => {
    const { ast, projectionInfo, scope } = this.props;
    const errors = ast.fold(
      (a) => name.map(n => validateColumnFieldName(n, existingFieldNames(a, projectionInfo.left, scope))).getOrElseValue([]),
      (b) => name.map(n => validateColumnFieldName(n, projectionInfo.right.getOrElse(
        () => { throw new Error('analysis failed previously; should not have reached this code path'); }
      ).outputSchema.map(item => item.name))).getOrElseValue([])
    );
    if (_.isEmpty(errors)) {
      funspec.match({
        some: (fun) => {
          switch (fun.name) {
            case SoQLFunCall.CountStar:
              this.addAggregate(none, fun, name);
              break;
            default:
              column.map(_col => this.addAggregate(column, fun, name));
          }
        },
        none: () => _.noop()
      });
    }

    this.setState({ errors });
  };

  onSelectColumn = (picked: PickableColumn) => {
    const column = option(picked);
    this.attemptAddAggregate(column, this.state.funspec, this.state.name);
    this.setState({ column });
  };

  onSelectFunction = (fc: FunSpec) => {
    const funspec = option(fc);
    this.attemptAddAggregate(this.state.column, funspec, this.state.name);
    this.setState({ funspec });
  };

  onNameChange = (value: string) => {
    const name = value ? option(value) : none;
    this.attemptAddAggregate(this.state.column, this.state.funspec, name);
    this.setState({ name });
  };

  addAggregate = (column: Option<PickableColumn>, fs: FunSpec, fn: FieldName) => {
    const { ast, compileAST } = this.props;
    const scope = getOnlyAggregates(this.props.scope);
    const name = fn.map((n) => ({ name: n, position: NoPosition })).orNull;
    /* Find the sub expr for the aggregate function. When the chosen aggregate
     * call is CountStar, a sub expr is not necessary. When trying to aggregate
     * a calculated column, check if it has as a ref available (implying it is
     * aliased) before defaulting to the underlying expr. */
    const subExpr = column.map((picked) => (
      matchPicked(
        picked,
        (vccr: ViewColumnColumnRef) => vccr.ref,
        (pexpr: ProjectionExpr) => pexpr.ref.getOrElseValue(pexpr.expr as any) as Expr
      )
    )).orNull;

    const newAggregate: UnAnalyzedSelectedExpression = {
      expr: {
        type: 'funcall',
        function_name: fs.name,
        args: (subExpr) ? [subExpr] : [],
        window: null
      },
      name
    };

    /* When adding an aggregate, if aggregating on an aliased column,
     * do not remove the associating aliased expr from the selection.
     * e.g. select 1 as num => select 1 as num, count(num) */
    const emptyArray = whichAnalyzer(() => [] as UnAnalyzedSelectedExpression[], () => [] as NamedExpr[])();
    const exprs: Either<UnAnalyzedSelectedExpression[], NamedExpr[]> = column.flatMap(picked => {
      return matchPicked(
        picked,
        (vccr: ViewColumnColumnRef) => some(emptyArray),
        (pexpr: ProjectionExpr) => pexpr.ref.flatMap(cref =>
          factorOption(this.props.projectionInfo.mapBoth(
            (maybePi) => maybePi.map(pi => pi.unanalyzed.exprs.filter(un => _.isEqual(_.get(un, 'name.name'), cref.value))),
            (maybePi: ProjectionInfoNA) => maybePi.map(pi => pi.selection.exprs.filter(epr =>  _.isEqual(epr.name?.name, cref.value)))
          ) as Either<Option<UnAnalyzedSelectedExpression[]>, Option<NamedExpr[]>>)
        )
      );
    }).getOrElseValue(emptyArray);

    if (hasGroupOrAggregate(ast.foldEither(some), scope)) {
      const keep: NamedExpr[] | UnAnalyzedSelectedExpression[] = exprs.fold(
        sExprs => sExprs.filter(sExpr => _.isUndefined(
          _.find(
            ast.left.selection.exprs,
            (se) => _.isEqual(_.get(se, 'name.name'), _.get(sExpr, 'name.name'))
          )
        )),
        sExprs => sExprs.filter(sExpr => _.isUndefined(
          _.find(
            ast.right.selection.exprs,
            (se) => _.isEqual(_.get(se, 'name.name'), _.get(sExpr, 'name.name'))
          )
        ))
      );

      const newAst = ast.foldEither(a => ({
        ...a,
        selection: {
          ...a.selection,
          exprs: [
            ...keep,
            ...a.selection.exprs,
            newAggregate
          ]
        }
      }));
      compileAST(newAst, true);
    } else {
      compileAST(ast.foldEither(a => ({
        ...a,
        selection: {
          ...a.selection,
          all_user_except: [],
          exprs: [
            ...exprs.foldEither(e => e),
            newAggregate
          ]
        }
      })), true);
    }
  };

  onUpdateType = (newExpr: Either<Expr, TypedExpr>) => {
    const type = newExpr.foldEither(e => e.type);
    if (type === 'column_ref') {
      this.setState({ column: none });
    } else {
      this.onUpdateExpr(newExpr);
    }
  };

  onUpdateExpr = (newExpr: Either<Expr, TypedExpr>) => {
    this.onSelectColumn({
      type: ColumnType.Query,
      column: {
        expr: newExpr.get,
        typedExpr: this.props.projectionInfo.fold(
          (pi) => toTyped(newExpr.left, this.props.scope, pi),
          (_pi) => newExpr.right
        ),
        name: 'new_aggregate', // not used, but required by PickableColumn type
        ref: none
      }
    });
  };

  // Why no dates here? Since 'Use date' is technically a function it was messing up the UI as it was double wrapping the function with two column-picker-containers
  //  and two kebabs. I failed to untangle it and since it wasn't a supported use case to make the initial filters when you add one a raw date value (you can still make it a date column or param)
  //  I just decided to remove the option from the kebab menu. This is something I would like to revisit.
  isTypeAllowed = (type: SoQLType) => (type !== SoQLType.SoQLFloatingTimestampT && type !== SoQLType.SoQLFloatingTimestampAltT);

  render() {
    const { columns, projectionInfo, scope, parameters } = this.props;
    const { column } = this.state;
    const onlyAggregates = getOnlyAggregates(scope);
    const availableScope = this.state.column.map(c => findAvailableScope(c, onlyAggregates)).getOrElseValue(onlyAggregates);
    const constraints = _.uniq(this.state.funspec.map(fs => findConstraints(fs, onlyAggregates)).getOrElseValue([]));

    // We need an Eexpr to show the ExpressionEditor: only used when our saved column is
    // a QueryColumn with a non-column-ref expr.
    const { showExprEditor, eexpr } = column.map(c => {
      if (isQueryColumn(c) && c.column.expr.type !== 'column_ref' && c.column.ref.isEmpty) {
        return {
          eexpr: whichAnalyzer(
            () => ({ typed: c.column.typedExpr, untyped: c.column.expr }),
            () => ({ expr: c.column.typedExpr })
          )(),
          showExprEditor: true
        };
      } else {
        return {
          eexpr: whichAnalyzer(
            () => ({ typed: null, untyped: nullLiteral }),
            () => ({ expr: null })
          )(),
          showExprEditor: false
        };
      }
    }).getOrElseValue({
      eexpr: whichAnalyzer(
        () => ({ typed: null, untyped: nullLiteral }),
        () => ({ expr: null })
      )(),
      showExprEditor: false
    });

    return (
      <div>
        <span className="add-expr aggregate-by-blank-state add-expr-container">
          <div className="column-picker-container">
            {showExprEditor ? <ExpressionEditor
              update={this.onUpdateExpr}
              remove={_.noop}
              columns={columns}
              parameters={parameters}
              scope={scope}
              isTypeAllowed={soqlType => true}
              eexpr={eexpr}
              showRemove={false}
              projectionInfo={projectionInfo} />
            : <ColumnPicker
              className="btn btn-default add-expr-column-picker aggregate-column-picker"
              prompt={t('select_column')}
              columns={columns}
              selected={toSelected(this.state.column)}
              projectionInfo={projectionInfo}
              onSelect={this.onSelectColumn}
              soqlTypeConstraints={constraints} />}
            <KebabMenu
              columns={columns}
              parameters={parameters}
              isTypeAllowed={this.isTypeAllowed}
              scope={scope}
              update={this.onUpdateType}
            />
          </div>
          <AggregateFunPicker
            prompt={t('select_calculation')}
            scope={availableScope}
            selected={this.state.funspec}
            onSelectFunction={this.onSelectFunction} />
          <RemoveNode onClick={this.props.onHideAggregateAddExpr} />
        </span>
        <EditAggregateName
          value={this.state.name.getOrElseValue('')}
          onChange={this.onNameChange}
          errors={this.state.errors} />
      </div>
    );
  }
}
