import classNames from 'classnames';
import { Either, factorOption } from 'common/either';
import { CollocationStatus } from 'common/core/collocation';
import { fetchTranslation } from 'common/locale';
import { CatalogDataType } from 'common/types/catalog/views';
import { QueryAnalysisSucceeded, QueryCompilationSucceeded } from 'common/types/compiler';
import {
  AnalyzedJoin,
  catalogDataTypeToSoQLType,
  Expr,
  isJoinByFromTable,
  JoinType,
  Scope,
  soqlTypeToCatalogDataType,
  SoQLType,
  typedNullLiteral,
  TypedJoin,
  TypedSoQLColumnRef,
  UnAnalyzedAst,
  UnAnalyzedJoin,
  TypedExpr
} from 'common/types/soql';
import { AssetType, View } from 'common/types/view';
import { hasDefaultJoinConditionShape } from '../lib/data-helpers';
import { usingSoda3EC, whichAnalyzer } from '../lib/feature-flag-helpers';
import { selectors as SelectRemoteStatus } from '../redux/statuses';
import {
  analysisFailure,
  analysisSuccess,
  compilationFailure,
  compilationSuccess,
  defaultTableAliasName,
  exprContainsRef,
  fourfourToTableSpecifier,
  getColumns,
  getColumnsNA,
  getLastAnalyzedAst,
  getLastUnAnalyzedAst,
  getRightmostLeafFromAnalysis,
  hasQuerySucceeded,
  tableSpecifierToFourFour,
  ViewColumnColumnRef,
  viewContextFromQuery
} from '../lib/selectors';
import { replaceAt } from 'common/util';
import { AppState, Query } from '../redux/store';
import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { none, some, Option, option } from 'ts-option';
import * as VisualContainer from './visualContainer';
import { CompileAST } from './visualContainer';
import VisualExpressionEditor from './VisualExpressionEditor';
import WithHandlingOfNonVisualStates from './visualNodes/WithHandlingOfNonVisualStates';
import '../styles/visual-join-editor.scss';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { getCurrentDomain } from 'common/currentDomain';
import { ForgeInlineMessage, ForgeChip, ForgeIcon, ForgeChipField, ForgeAutocomplete, ForgeSelect } from '@tylertech/forge-react';
import { IAutocompleteOption, IconComponentDelegate } from '@tylertech/forge';
import { Eexpr, EexprNA, EditableExpressionNA, UnEditableExpressionNA } from '../types';
import { scrollToPosition } from '../lib/scroll-helpers';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_join_editor');

interface DatasetAutocompleteProps {
  query: Query;
  compileAST: CompileAST;
  baseColumns: TypedSoQLColumnRef[];
}

interface SearchResult {
  name: string;
  id: string;
  columns_datatype: CatalogDataType[];
  columns_field_name: string[];
}

interface AutocompleteResult {
  title: string;
  id: string;
}

const searchResultToColumnRefs = (result: SearchResult): TypedSoQLColumnRef[] => {
  return result.columns_field_name.map((fieldName, index) => {
    const dataType = result.columns_datatype[index];
    return {
      type: 'column_ref',
      value: fieldName,
      qualifier: defaultTableAliasName(result.name),
      soql_type: catalogDataTypeToSoQLType(dataType)
    };
  });
};

class DatasetAutocomplete extends React.Component<DatasetAutocompleteProps> {

  getResults = (filterText: string): Promise<IAutocompleteOption[]> => {
    if (_.isEmpty(filterText)) {
      return Promise.resolve([] as IAutocompleteOption[]);
    }
    const noResults: IAutocompleteOption[] = [{
      label: t('no_results'),
      value: null,
      disabled: true
    }];
    const q = encodeURIComponent(filterText) || '';
    const domain = getCurrentDomain();
    const url = `/api/catalog/v1/autocomplete?q=${q}&deduplicate=false&published=true&explicitly_hidden=false&limit=10&show_visibility=true&domains=${domain}&order=relevance&search_context=${domain}&only=filters,datasets,system_datasets`;
    return fetch(url, { credentials: 'same-origin' })
      .then((response) => response.json())
      .then(
        (searchResults: { results: AutocompleteResult[] }) => {
          const results: IAutocompleteOption[] = searchResults.results.map(
            (result) => ({ value: result.id, label: result.title })
          );
          if (results.length) return results;
          return noResults;
        },
        (error) => {
          console.error('Failed to fetch data', error);
          return noResults; // Should this be an error message instead?
        }
      )
      .catch((ex) => {
        console.error('Error parsing JSON', ex);
        return noResults; // Should this be an error message instead?
      });
  };

  onAddSelectedOption = (id?: string) => {
    if (id === undefined) return;

    const domain = getCurrentDomain();
    const url = `/api/catalog/v1?ids=${id}&domains=${domain}`;

    fetch(url, { credentials: 'same-origin' })
      .then((response) => response.json())
      .then(
        (searchResults: { results: { resource: SearchResult }[] }) => {

          if (!searchResults.results.length) return;

          const result = searchResults.results[0].resource;

          this.getAst().map((ast) => {
            const name = fourfourToTableSpecifier(result.id);
            const existing = ast.joins
              .map((j) => {
                if (isJoinByFromTable(j.from)) {
                  return j.from.from_table.name;
                }
                return '';
              })
              .filter((tableName) => tableName.length > 0);

            const getSuggestedColumnMatch = (
              baseColumns: TypedSoQLColumnRef[],
              joinColumns: TypedSoQLColumnRef[]
            ): [TypedExpr, TypedExpr] => {
              const baseColTypes = new Set(baseColumns.map((col) => col.soql_type));
              const joinColTypes = new Set(joinColumns.map((col) => col.soql_type));
              const sharedTypes = new Set([...baseColTypes].filter((typ) => joinColTypes.has(typ)));
              if (sharedTypes.size === 0) {
                // if there are no type matches available, just the first two columns from each dataset
                // if there is no first column... nullLiteral? ¯\_(ツ)_/¯
                return [baseColumns[0] || typedNullLiteral, joinColumns[0] || typedNullLiteral];
              } else {
                // using ! because we've already established that they both have this type
                const firstBaseColWithSharedType = baseColumns.find((baseCol) =>
                  sharedTypes.has(baseCol.soql_type)
                )!;
                const matchedJoinCol = joinColumns.find(
                  (joinCol) => joinCol.soql_type === firstBaseColWithSharedType.soql_type
                )!;
                return [firstBaseColWithSharedType, matchedJoinCol];
              }
            };
            const joinColumns = searchResultToColumnRefs(result);
            const baseColumns = this.props.baseColumns;
            const args = getSuggestedColumnMatch(baseColumns, joinColumns);
            if (!_.includes(existing, name)) {
              const join: TypedJoin = {
                from: {
                  type: 'from_table',
                  from_table: {
                    alias: defaultTableAliasName(result.name),
                    name
                  }
                },
                on: {
                  type: 'funcall',
                  function_name: 'op$=',
                  args: args,
                  window: null,
                  soql_type: SoQLType.SoQLBooleanT
                },
                type: 'JOIN',
                lateral: false
              };

              this.props.compileAST(
                {
                  ...ast,
                  joins: [...ast.joins, join]
                },
                true
              );
            }
          });
        },
        (error) => console.error('Failed to fetch data', error)
      )
      .catch((ex) => console.error('Error parsing JSON', ex));
  };

  getAst = (): Option<UnAnalyzedAst> => {
    return usingSoda3EC()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getLastUnAnalyzedAst(this.props.query);
  };

  getJoins = (): Option<UnAnalyzedJoin[]> => {
    return this.getAst().map((ast) => ast.joins);
  };

  onRemoveSelectedOption = (removeId?: string) => {
    if (removeId === undefined) return;

    this.getAst().map((ast) => {
      const toRemove = removeId;
      const joins = ast.joins;

      const newSelection = {
        exprs: ast.selection.exprs.filter((select) => !exprContainsRef(select.expr, joins, toRemove)),
        all_system_except: null,
        all_user_except: []
      };

      const newGroupBys = ast.group_bys.filter((gb) => !exprContainsRef(gb, joins, toRemove));

      const newOrderBys = ast.order_bys.filter((ob) => !exprContainsRef(ob.expr, joins, toRemove));

      // TODO: this is a product question: what do we do with an Expr that contains references
      // to the join and the user wants to remove it? Or do we remove SubExprs and do ???? in
      // all the edge cases
      const newWhere = option(ast.where)
        .map((where: Expr) => (exprContainsRef(where, joins, toRemove) ? null : where))
        .getOrElseValue(null);

      // TODO: having

      const newJoins = joins.filter(
        (join) =>
          isJoinByFromTable(join.from) && tableSpecifierToFourFour(join.from.from_table.name) !== toRemove
      );

      this.props.compileAST(
        {
          ...ast,
          selection: newSelection,
          where: newWhere,
          group_bys: newGroupBys,
          joins: newJoins,
          order_bys: newOrderBys
        },
        true
      );
    });
  };

  getSelectedOptions = (): SearchResult[] => {
    if (usingSoda3EC()) {
      return analysisSuccess(this.props.query.analysisResult)
        .map((c: QueryAnalysisSucceeded) => {
          const fourfours: string[] = this.getJoins()
            .map((joins) =>
              joins
                .map((j) => {
                  if (isJoinByFromTable(j.from)) {
                    return tableSpecifierToFourFour(j.from.from_table.name);
                  }
                  return '';
                })
                .filter((tableSpecifier) => tableSpecifier.length > 0)
            )
            .getOrElseValue([]);

          return _.flatMap(c.views, (view, alias) => {
            if (_.includes(fourfours, view.id)) {
              return [{
                name: view.name,
                id: view.id,
                columns_datatype: view.columns.map(col => soqlTypeToCatalogDataType(col.dataTypeName)),
                columns_field_name: view.columns.map(col => col.fieldName)
              }];
            } else {
              return [];
            }
          });
        })
        .getOrElseValue([]);
    } else {
      return compilationSuccess(this.props.query.compilationResult)
        .map((c: QueryCompilationSucceeded) => {
          const fourfours: string[] = this.getJoins()
            .map((joins) =>
              joins
                .map((j) => {
                  if (isJoinByFromTable(j.from)) {
                    return tableSpecifierToFourFour(j.from.from_table.name);
                  }
                  return '';
                })
                .filter((tableSpecifier) => tableSpecifier.length > 0)
            )
            .getOrElseValue([]);

          return _.flatMap(c.views, (view, alias) => {
            if (_.includes(fourfours, view.id)) {
              return [{
                name: view.name,
                id: view.id,
                columns_datatype: view.columns.map(col => soqlTypeToCatalogDataType(col.dataTypeName)),
                columns_field_name: view.columns.map(col => col.fieldName)
              }];
            } else {
              return [];
            }
          });
        })
        .getOrElseValue([]);
    }
  };

  render() {
    return (
      <ForgeAutocomplete
        mode="stateless"
        allowUnmatched={false}
        debounce={500}
        filterOnFocus={false}
        filter={this.getResults}
        popupClasses='join-asset-picker-popup'
        on-forge-autocomplete-select={(evt: CustomEvent) => {
          this.onAddSelectedOption(evt.detail.value);
        }}
      >
        <ForgeChipField>
          {this.getSelectedOptions().map((result) => {
            return (
              <ForgeChip
                key={result.id}
                slot="member"
                value={result.id}
                type="input"
                on-forge-chip-delete={(evt: CustomEvent) => this.onRemoveSelectedOption(evt.detail.value)}
              >
                {result.name}
              </ForgeChip>
            );
          })
          }
          <label slot="label" htmlFor="chip-field-input">{t('search')}</label>
          <input autoComplete="off" type="text" id="chip-field-input" data-testid="data-autocomplete-chip-field-input" />
          <ForgeIcon slot="leading" name="search"></ForgeIcon>
        </ForgeChipField>
      </ForgeAutocomplete>
    );
  }
}

interface JoinTypeDropdownProps {
  join: UnAnalyzedJoin | TypedJoin;
  updateJoin: (j: UnAnalyzedJoin | TypedJoin) => void;
}

const getJoinIconName = (type: JoinType): string => {
  switch (type) {
    case 'LEFT OUTER JOIN': return 'set_left_center';
    case 'RIGHT OUTER JOIN': return 'set_center_right';
    case 'FULL OUTER JOIN': return 'set_all';
    default: return 'set_center';
  }
};

function JoinTypeDropdown({ join, updateJoin }: JoinTypeDropdownProps) {
  const onSelection = (e: JoinType) => {
    updateJoin({ ...join, type: e });
  };

  const titleOf = (jt: JoinType): string => {
    if (jt === 'LEFT OUTER JOIN') return t('left_join');
    if (jt === 'RIGHT OUTER JOIN') return t('right_join');
    if (jt === 'FULL OUTER JOIN') return t('outer_join');
    return t('inner_join');
  };

  const iconBuilder = (jt: JoinType): HTMLElement => {
    const props = {
      name: getJoinIconName(jt)
    };
    return (new IconComponentDelegate({ props }).element as unknown as HTMLElement);
  };

  const options = (['JOIN', 'LEFT OUTER JOIN', 'RIGHT OUTER JOIN', 'FULL OUTER JOIN'] as JoinType[])
    .map(kind => ({ label: titleOf(kind), value: kind, leadingBuilder: () => iconBuilder(kind) }));

  return (
    <div className="join-type">
      <ForgeSelect
        options={options}
        value={join.type}
        label={t('join_type')}
        onChange={(event: CustomEvent) => onSelection(event.detail)}
        data-testid='join-type-select'
      />
    </div>
  );
}

interface JoinToLabelProps {
  view: View;
}

class JoinToLabel extends React.Component<JoinToLabelProps> {
  render() {
    const { view } = this.props;
    const iconName = view.assetType === AssetType.Filter ? 'database_filter' : 'database';
    return (
      <div className="join-to-label">
        <ForgeIcon name={iconName} />
        <div>
          <a className="dataset-link" href={`/d/${view.id}/explore`} target="_blank" rel="noreferrer">
            {view.name}
          </a>
          {view.description && <div className="text-quiet">{view.description}</div>}
        </div>
      </div>
    );
  }
}

interface JoinClauseEditorProps {
  updateJoin: (newJoin: UnAnalyzedJoin | TypedJoin) => void;
  removeJoin: () => void;
  scope: Scope;
  query: Query;
  unanalyzedJoin: UnAnalyzedJoin | TypedJoin;
  analyzedJoin: Option<AnalyzedJoin | TypedJoin>;
  collocationStatus: Option<CollocationStatus>;
  parameters: ClientContextVariable[];
}
class JoinClauseEditor extends React.Component<JoinClauseEditorProps> {
  getJoinableColumnsFromBase = (): ViewColumnColumnRef[] => whichAnalyzer(getColumns, getColumnsNA)(this.props.query).get;

  getJoinToView = (): Option<View> => {
    return viewContextFromQuery(this.props.query).flatMap((vc) => {
      const joinSelect = this.props.unanalyzedJoin.from;
      if (isJoinByFromTable(joinSelect)) {
        return option(vc[joinSelect.from_table.name]);
      } else {
        return none;
      }
    });
  };

  updateExpr = (newExpr: Either<Expr, TypedExpr>) => {
    this.props.updateJoin({
      ...this.props.unanalyzedJoin,
      on: newExpr.foldEither(e => e)
    });
  };

  getEexpr = (): Option<Either<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>> => factorOption<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>(whichAnalyzer(
    () => {
      const failure = compilationFailure(this.props.query.compilationResult);
      const success = compilationSuccess(this.props.query.compilationResult);
      if (failure.isDefined)
        return failure.map((failedCompilation) => ({
          untyped: this.props.unanalyzedJoin.on,
          error: failedCompilation
        }));
      return success.flatMap((successCompilation) =>
        this.props.analyzedJoin.map((aj) => ({ untyped: this.props.unanalyzedJoin.on, typed: aj.on }))
      );
    },
    () => {
      const failure = analysisFailure(this.props.query.analysisResult);
      const success = analysisSuccess(this.props.query.analysisResult);
      if (failure.isDefined)
        return failure.map((failedCompilation) => ({
          expr: this.props.unanalyzedJoin.on,
          error: failedCompilation
        } as UnEditableExpressionNA<TypedExpr>));
      return success.map((successCompilation) => ({ expr: this.props.unanalyzedJoin.on } as EditableExpressionNA<TypedExpr>));
    }
   )()
  );

  connector = (
    <div className='join-connector' />
  );

  render() {
    const { unanalyzedJoin, updateJoin, removeJoin, query, scope, parameters } = this.props;
    const classes = classNames({
      'special-join-clause': hasDefaultJoinConditionShape(unanalyzedJoin)
    });

    return this.getEexpr().match({
      some: (eexpr) => {
        return (
          <div className="join-clause-editor">
            <div className="join-to-details">
              <div className="join-to-dataset">
                <JoinTypeDropdown join={unanalyzedJoin} updateJoin={updateJoin} />
                {this.connector}
                <ForgeIcon className="join-step-icon" name={getJoinIconName(unanalyzedJoin.type)} />
                {this.connector}
                {this.getJoinToView()
                  .map((view) => <JoinToLabel key={view.id} view={view} />)
                  .getOrElseValue(<div>{t('unknown_view')}</div>)}
              </div>
              <div className="join-to-help">
                <a className="learn-more" href="#">
                  {t('learn_more')}
                </a>
              </div>
            </div>
            <div className={classes}>
              <VisualExpressionEditor
                columns={this.getJoinableColumnsFromBase()}
                parameters={parameters}
                eexpr={eexpr}
                isTypeAllowed={(st: SoQLType) => st === SoQLType.SoQLBooleanT}
                remove={removeJoin}
                querySucceeded={hasQuerySucceeded(query)}
                scope={scope}
                showRemove={false}
                unAnalyzedJoin={unanalyzedJoin}
                update={this.updateExpr}
              />
            </div>
          </div>
        );
      },
      none: () => null
    });
  }
}

interface JoinFromProps {
  query: Query;
  compileAST: CompileAST;
  baseColumns: TypedSoQLColumnRef[];
}
class JoinFromEditor extends React.Component<JoinFromProps> {
  render() {
    return (
      <div className="join-to-dataset-autocomplete">
        <DatasetAutocomplete
          baseColumns={this.props.baseColumns}
          query={this.props.query}
          compileAST={this.props.compileAST}
        />
      </div>
    );
  }
}

type Props = VisualContainer.VisualContainerProps & {
  // TODO: EN-46617 Get rid of this prop if we don't end up using it for this issue
  collocationStatus: Option<CollocationStatus>;
};
interface State {
  scrollPosition: Option<number>;
}

class VisualJoinEditor extends React.Component<Props, State> {
  state = {
    scrollPosition: none
  } as State;

  getSnapshotBeforeUpdate(): Option<number> {
    // Grab the scroll position we're at before we update.
    const element = document.querySelector('.scroll-container > div');
    if (element) {
      return some(element.getBoundingClientRect().top);
    }
    return none;
  }

  componentDidUpdate(
    prevProps: VisualContainer.VisualContainerProps,
    prevState: State,
    snapshot: Option<number>
  ) {
    const remoteStatus = this.props.remoteStatusInfo;
    if (SelectRemoteStatus.inProgress(remoteStatus).nonEmpty &&
      !this.state.scrollPosition.isDefined && snapshot.isDefined) {
      // We show a compiling message after an edit, which is what loses the scroll position.
      // So we save the scroll position we had just before compilation to state.
      this.setState({ scrollPosition: snapshot });
    } else if ((SelectRemoteStatus.canRunCompiledQuery(remoteStatus).nonEmpty ||
      SelectRemoteStatus.queryRanSuccessfully(remoteStatus).nonEmpty) &&
      this.state.scrollPosition.isDefined) {
      // Once compilation succeeds and we're showing the VEE again, scroll to the saved position.
      scrollToPosition('.scroll-container > div', this.state.scrollPosition.getOrElseValue(0));
      this.setState({ scrollPosition: none });
    }
  }

  getBaseColumns = (): ViewColumnColumnRef[] => {
    return whichAnalyzer(getColumns, getColumnsNA)(this.props.query).get.filter((vccr) => vccr.ref.qualifier === null);
  };

  render() {
    const { collocationStatus, query, compileAST, parameters } = this.props;
    const maybeBaseColumns = option(this.getBaseColumns().map((viewCol) => viewCol.typedRef));
    const ast = usingSoda3EC() ? getRightmostLeafFromAnalysis(query) : getLastUnAnalyzedAst(query);
    return (
      <div className="grid-datasource-components scroll-container visual-join-editor">
        <WithHandlingOfNonVisualStates remoteStatusInfo={this.props.remoteStatusInfo} query={query}>
          <div className="visual-join-editor-content">
            {maybeBaseColumns.map<JSX.Element | null>(baseColumns => (
              <JoinFromEditor
                key="quiet-eslint"
                baseColumns={baseColumns}
                query={query}
                compileAST={compileAST} />
            )).getOrElseValue(null)}

            {ast
              .map<JSX.Element[] | null>((unanalyzed) => {
                if (unanalyzed.joins.length === 0) {
                  return [
                    <div key="0">
                      <ForgeInlineMessage>
                        <ForgeIcon slot="icon" name="info" />
                        <div >
                          {t('no_joins')}
                        </div>
                      </ForgeInlineMessage>
                    </div>
                  ];
                }
                return unanalyzed.joins.map((unanalyzedJoin, idx) => {
                  // doing it this way because lodash's zip typescript types are wrong
                  const analyzed = usingSoda3EC()
                    ? getRightmostLeafFromAnalysis(query)
                    : getLastAnalyzedAst(query);
                  const analyzedJoin = analyzed.map((an) => an.joins[idx]);

                  const updateJoin = (newJoin: UnAnalyzedJoin | TypedJoin) => {
                    this.props.compileAST(
                      {
                        ...unanalyzed,
                        joins: replaceAt(unanalyzed.joins, newJoin, idx)
                      },
                      true
                    );
                  };

                  const removeJoin = () => {
                    this.props.compileAST(
                      {
                        ...unanalyzed,
                        // FIXME: tsc was confused about if it was UnAnalyzedJoin[] or TypedJoin[],
                        // so I picked one for it. Remove the type coercion when deleting unanalyzed stuff.
                        joins: (unanalyzed.joins as TypedJoin[]).filter((j, i) => i !== idx)
                      },
                      true
                    );
                  };

                  return (
                    <JoinClauseEditor
                      key={idx}
                      query={query}
                      scope={this.props.scope}
                      unanalyzedJoin={unanalyzedJoin}
                      analyzedJoin={analyzedJoin}
                      removeJoin={removeJoin}
                      updateJoin={updateJoin}
                      collocationStatus={collocationStatus}
                      parameters={parameters}
                    />
                  );
                });
              })
              .getOrElseValue(null)}
            {ast.forEach(
              (unanalyzed) =>
                unanalyzed.joins.length !== 0 && (
                  <div className="join-next-up" dangerouslySetInnerHTML={{ __html: t('next_up') }} />
                )
            )}
          </div>
        </WithHandlingOfNonVisualStates>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: VisualContainer.ExternalProps) => {
  return {
    ...VisualContainer.mapStateToProps(state, props),
    collocationStatus: state.collocationInfo.map((ci) => ci.collocationStatus),
    parameters: state.clientContextInfo.variables
  };
};

export default connect(
  mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(VisualJoinEditor);
