import _ from 'lodash';
import { connect } from 'react-redux';
import ViewTableRow from 'datasetManagementUI/components/DatasetContentsTableRows/ViewTableRow';

export function mapStateToProps({ entities }, { fourfour }) {
  const view = entities.views[fourfour];
  const columns = _.get(view, 'columns');
  const rowIdentifier = columns.find((column) => column.id === view.rowIdentifierColumnId)?.name;

  return {
    rowCount: _.get(view, 'rowCount', 0),
    columnCount: columns ? columns.length : 0,
    rowLabel: _.get(view, 'metadata.rowLabel', ''),
    rowIdentifier
  };
}

export default connect(mapStateToProps)(ViewTableRow);
