import Pager from 'common/components/Pager';
import { fetchTranslation } from 'common/locale';
import { QueryAnalysisSucceeded, QueryCompilationSucceeded } from 'common/types/compiler';
import { UnAnalyzedAst } from 'common/types/soql';
import { lastInChain, querySuccess } from '../lib/selectors';
import { QueryMetaSuccess } from '../redux/store';
import React from 'react';
import { connect } from 'react-redux';
import { none, Option, some } from 'ts-option';
import '../styles/grid-pager.scss';
import * as VisualContainer from './visualContainer';
import formatString from 'common/js_utils/formatString';
import { usingSoda3EC, whichAnalyzer } from '../lib/feature-flag-helpers';
import { Either, factorOption } from 'common/either';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_pager');

// this component will be depreciated when we get paging working on the ag-grid... eventually
export class GridPager extends React.Component<VisualContainer.VisualContainerProps> {
  isDisabled = () => {
    // query is running
    if (this.props.query.isQueryInProgress) {
      return true;
    }
    // count(*) is running, we have no idea how many pages
    if (this.getMeta().isEmpty) {
      return true;
    }
    // if there is no successful query result, we are disabled
    return this.getCompilationResult().isEmpty;
  };

  getCompilationResult = (): Option<Either<QueryCompilationSucceeded, QueryAnalysisSucceeded>> => {
    const query = querySuccess(this.props.query.queryResult);
    return factorOption<QueryCompilationSucceeded, QueryAnalysisSucceeded>(
      whichAnalyzer(
        () => query.map((qr) => qr.compiled),
        () => query.flatMap((qr) => qr.analyzed)
      )()
    );
  };

  onChangePage = (page: number) => {
    this.getAst().forEach((ast) => {
      this.props.runAST(ast, some(page));
    });
  };

  getAst = (): Option<UnAnalyzedAst> => {
    return this.getCompilationResult()
      .map((result) =>
        result.fold(
          (r) => r.unanalyzed,
          (r) => r.ast
        )
      )
      .map(lastInChain);
  };

  getCurrentPage = (): number => {
    return this.props.query.paginationState.currentPage;
  };

  getMeta = (): Option<QueryMetaSuccess> => {
    return querySuccess(this.props.query.queryResult)
      .map((qs) => qs.meta)
      .flatMap((meta) => {
        if (meta.type === 'query_meta_success') {
          return some(meta);
        }
        return none;
      });
  };

  getCount = (): Option<number> => {
    return this.getMeta().map((m) => m.rowCount);
  };

  getOffset = (): number => {
    const { pageSize, currentPage } = this.props.query.paginationState;
    return (currentPage - 1) * pageSize;
  };

  getResultsPerPage = (): number => this.props.query.paginationState.pageSize;

  getRowText(count: number, lower: number, upper: number): string {
    switch (count) {
      case 0:
        return t('no_rows');
      case 1:
        return t('one_row');
      default:
        return formatString(t('showing_rows'), { 0: lower + 1, 1: upper, 2: count });
    }
  }

  render() {
    const inner = this.getCount().match({
      none: () => (
        <div className="fetching-row-count">
          <span className="spinner-default"></span>
          <span className="text-quiet">{t('fetching')}</span>
        </div>
      ),
      some: (count) => {
        const lower = this.getOffset();

        if (this.getResultsPerPage() === 0) {
          return (
            <div className="fetching-row-count">
              <span className="error-text text-quiet">{t('limit_zero')}</span>
            </div>
          );
        } else if (
          this.getCount()
            .map((rowCount) => rowCount > 0 && rowCount <= this.getOffset())
            .getOrElseValue(false)
        ) {
          return (
            <div className="fetching-row-count">
              <span className="error-text text-quiet">{t('offset_greater_than_count')}</span>
            </div>
          );
        }

        const upper = Math.min(lower + this.getResultsPerPage(), count);

        return (
          <div className="grid-pager-counts">
            <Pager
              isDisabled={this.isDisabled()}
              changePage={this.onChangePage}
              currentPage={this.getCurrentPage()}
              resultCount={count}
              resultsPerPage={this.getResultsPerPage()}
            />

            <span className="text-quiet">{this.getRowText(count, lower, upper)}</span>
          </div>
        );
      }
    });

    return <div className="grid-pager">{inner}</div>;
  }
}

export default connect(
  VisualContainer.mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(GridPager);
