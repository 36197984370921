import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';
import { commaify } from 'common/formatNumber';

const scope = 'dataset_management_ui.dataset_contents';

export default function ViewTableRow({ rowCount, columnCount, rowLabel, rowIdentifier }) {
  return (
    <tr id="view-table-row">
      <td className="row-title">{I18n.t('published', { scope })}</td>
      <td>{commaify(rowCount)}</td>
      <td>{commaify(columnCount)}</td>
      <td className="published-version">{rowLabel}</td>
      <td className="published-version">{rowIdentifier}</td>
    </tr>
  );
}

ViewTableRow.propTypes = {
  rowCount: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  rowLabel: PropTypes.string,
  rowIdentifier: PropTypes.string
};
