import _ from 'lodash';
import { Option } from 'ts-option';
import { TypedExpr, TypedSelect, UnAnalyzedAst } from 'common/types/soql';
import { Query } from '../../redux/store';
import { getLastUnAnalyzedAst, getRightmostLeafFromAnalysis, lastInChain } from '../../lib/selectors';
import { usingSoda3EC } from '../../lib/feature-flag-helpers';
import { VisualColumnManager } from '../VisualColumnManager';

function getAstFromQuery(query: Query): Option<UnAnalyzedAst | TypedSelect> {
  if (usingSoda3EC()) {
    return getRightmostLeafFromAnalysis(query);
  } else {
    return getLastUnAnalyzedAst(query);
  }
}

function addOrReplaceOrderBy(this: VisualColumnManager, expr: TypedExpr, ascending: boolean, sortIndex: number) {
  getAstFromQuery(this.props.query).map(unanalyzed => {
    const order_bys = _.clone(unanalyzed.order_bys);
    const newOrderBy = {
      ascending, expr,
      null_last: true
    };
    if (sortIndex > -1) {
      order_bys.splice(sortIndex, 1, newOrderBy);
    } else {
      order_bys.push(newOrderBy);
    }

    this.props.compileAST({ ...unanalyzed, order_bys }, false);
  });
}

function clearOrderBy(this: VisualColumnManager, sortIndex: number) {
  getAstFromQuery(this.props.query).map(unanalyzed => {
    const order_bys = _.clone(unanalyzed.order_bys);
    order_bys.splice(sortIndex, 1);

    this.props.compileAST({ ...unanalyzed, order_bys }, false);
  });
}

function reorderOrderBy(this: VisualColumnManager, sortIndex: number, newSortIndex: number) {
  getAstFromQuery(this.props.query).map(unanalyzed => {
    const order_bys = _.clone(unanalyzed.order_bys);
    const movedOrderBy = order_bys.splice(sortIndex, 1)[0];
    order_bys.splice(newSortIndex, 0, movedOrderBy);

    this.props.compileAST({ ...unanalyzed, order_bys }, false);
  });
}

export { addOrReplaceOrderBy, clearOrderBy, reorderOrderBy };
