import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';
import { commaify } from 'common/formatNumber';

const scope = 'dataset_management_ui.dataset_contents';

export default function DraftTableRow({
  importRowCount,
  displayType,
  errorRowCount,
  columnCount,
  rowIdentifier,
  rowLabel,
  rowAction,
  exportErrorsLink
}) {
  return (
    <tr id="draft-table-row">
      <td className="row-title">{I18n.t('draft', { scope })}</td>
      <td className={displayType}>
        {importRowCount >= 0 ? (
          <div>
            <div>
              {commaify(importRowCount)}
              {rowAction && <span> {I18n.t(rowAction, { scope })}</span>}
            </div>
            {rowAction === 'update' && <div className="note">{I18n.t('upsert_explanation', { scope })}</div>}
            {errorRowCount !== 0 && (
              <div className="error-rows">
                {commaify(errorRowCount)} {I18n.t('errors', { scope })}
                <span className="socrata-icon-question-inverse">
                  <div className="tooltip-text">{I18n.t('tooltip_text', { scope })}</div>
                </span>
                <span className="error-link">
                  <a href={exportErrorsLink}>{I18n.t('dataset_management_ui.export_errors')}</a>
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="metadata-section busy">
            <span className="spinner-default" />
          </div>
        )}
      </td>
      <td className={displayType}>{commaify(columnCount)}</td>
      <td>{rowLabel}</td>
      <td>{rowIdentifier}</td>
    </tr>
  );
}

DraftTableRow.propTypes = {
  importRowCount: PropTypes.number.isRequired,
  displayType: PropTypes.string,
  errorRowCount: PropTypes.number.isRequired,
  columnCount: PropTypes.number.isRequired,
  rowIdentifier: PropTypes.string,
  rowLabel: PropTypes.string,
  rowAction: PropTypes.string,
  exportErrorsLink: PropTypes.string
};
