import React from 'react';
import { connect } from 'react-redux';

import capitalize from 'lodash/fp/capitalize';
import '../styles/parameters-editor.scss';
import { Option, none, some } from 'ts-option';

import { IMenuSelectEventData } from '@tylertech/forge';
import {
  ForgeButton,
  ForgeCard,
  ForgeTable,
  ForgeMenu,
  ForgeTooltip,
  ForgeIcon,
  ForgeIconButton
} from '@tylertech/forge-react';

import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { ClientContextVariable, formatParameterDate, Override } from 'common/types/clientContextVariable';
import { Parameter, SoQLType } from 'common/types/soql';
import ColumnTypeIcon from 'common/components/ColumnTypeIcon';
import { AppState, OpenModalType, AppStateContextualEventHandlers, Query } from '../redux/store';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';
import { sortClientContextVariables } from 'common/core/client_context_variables';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import { getLastUnAnalyzedAst } from '../lib/selectors';
import { containsParameter, getAllExpr } from '../lib/soql-helpers';
import WithHandlingOfNonVisualStates from './visualNodes/WithHandlingOfNonVisualStates';
import ParameterOverrideInput from './ParameterOverrideInput';
import { RemoteStatusInfo } from '../redux/statuses';

const t = (k: string, options = {}, scope = 'shared.explore_grid.parameters_editor') =>
  I18n.t(k, { ...options, scope });

interface StateProps {
  parameters: ClientContextVariable[];
  viewId: string;
  contextualEventHandlers: AppStateContextualEventHandlers;
  query: Query;
  remoteStatusInfo: Option<RemoteStatusInfo>;
}

type OwnProps = {
  canModifyParameters: boolean;
};

interface DispatchProps {
  dispatch: Dispatcher;
  showToast: (message: string, icon: Option<JSX.Element>, duration?: number) => void;
  openParameterModal: () => void;
  openParameterEditorModal: (parameter: ClientContextVariable) => void;
  updateParameterOverride: (parameter: ClientContextVariable, override: Override) => void;
}

export type ParametersTableProps = StateProps &
  OwnProps & {
    showToast: (message: string, icon: Option<JSX.Element>, duration?: number) => void;
    deleteParameter: (
      viewId: string,
      parameterName: string,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => void;
    openParameterModal: () => void;
    openParameterEditorModal: (parameter: ClientContextVariable) => void;
    parameterDeleteEnabled: (paramName: string) => boolean;
    updateParameterOverride: (parameter: ClientContextVariable, override: Override) => void;
  };

enum ParameterActionOption {
  DELETE,
  EDIT
}

export class ParametersTable extends React.Component<ParametersTableProps> {
  jsxToHtmlElementService: JsxToHtmlElementService;

  constructor(props: ParametersTableProps) {
    super(props);
    this.jsxToHtmlElementService = new JsxToHtmlElementService();
  }

  componentWillUnmount = () => {
    this.jsxToHtmlElementService.deleteAll();
  };

  createNewElement = () => {
    const createNewButton = (
      <ForgeButton type="raised">
        <button type="button" id="show-dialog-button" onClick={this.props.openParameterModal}>
          {t('button')}
        </button>
      </ForgeButton>
    );
    const noCurrentParametersText = (
      <p className="forge-typography--body2" id="no-parameters-text">
        {t('no_parameters')}
      </p>
    );
    return (
      <ForgeCard className={'parameter-card'} outlined>
        <div className={'create-new-parameter'}>
          <img src="https://cdn.forge.tylertech.com/v1/images/spot/developer-spot.svg" alt="" />
          <div className="create-new_parameter-frame">
            <p className="forge-typography--body2">{t('empty_label')}</p>
            {this.props.canModifyParameters ? createNewButton : noCurrentParametersText}
          </div>
        </div>
      </ForgeCard>
    );
  };

  showParameters = (parameters: ClientContextVariable[]) => {
    const nameTemplate = (index: number, div: any, parameter: ClientContextVariable) => {
      const jsx = (
        <div className="parameter-name-cell">
          <ColumnTypeIcon type={parameter.dataType} forge={true} />
          <div className="parameter-name-container" title={parameter.name}>
            {parameter.name}
          </div>
        </div>
      );
      return this.jsxToHtmlElementService.wrapJsx(jsx, `${parameter.name}-${parameter.viewId}`);
    };

    const dataTypeTemplate = (index: number, div: any, parameter: ClientContextVariable) => {
      return t(`data_types.${parameter.dataType}`);
    };

    const defaultValueTemplate = (index: number, div: any, parameter: ClientContextVariable) => {
      switch (parameter.dataType) {
        case SoQLType.SoQLBooleanT:
        case SoQLType.SoQLBooleanAltT:
          return capitalize(parameter.defaultValue);
        case SoQLType.SoQLFloatingTimestampT:
          return formatParameterDate(parameter.defaultValue);
        default:
          const jsx = (
            <div className="default-value-text-container" title={parameter.defaultValue}>
              {parameter.defaultValue}
            </div>
          );
          return this.jsxToHtmlElementService.wrapJsx(jsx, `${parameter.name}-${parameter.viewId}-default`);
      }
    };

    const overrideInputTemplate = (_index: number, _div: any, parameter: ClientContextVariable) => {
      const jsx = (
        <div className='parameter-test-cell'>
          <span className='parameter-test-cell-input'>
            <ParameterOverrideInput
              parameter={parameter}
              testValueDiffersCallback={(override: Override) => this.props.updateParameterOverride(parameter, override)}
            />
          </span>
          {parameter.overrideValue &&
            <ForgeIcon name='warning' className='warning-icon'>
              <ForgeTooltip position="bottom">
                {t('values_differ')}
              </ForgeTooltip>
            </ForgeIcon>
          }
        </div>
      );
      return this.jsxToHtmlElementService.wrapJsx(jsx, `${parameter.name}-${parameter.viewId}-test`);
    };

    const actionsTemplate = (index: number, div: any, parameter: ClientContextVariable) => {
      if (parameter.inherited) {
        const jsx = (
          <div>
            {t('inherited')}
            <ForgeTooltip delay={100} position={'right'}>
              {t('inherited_tooltip')}
            </ForgeTooltip>
          </div>
        );
        return this.jsxToHtmlElementService.wrapJsx(jsx, `${parameter.name}-${parameter.viewId}-inherited`);
      } else {
        const deleteEnabled = this.props.parameterDeleteEnabled(parameter.name);
        const menuOptions = [
          { value: ParameterActionOption.EDIT, label: t('edit') },
          { value: ParameterActionOption.DELETE, label: t('delete') }
        ];

        const onMenuSelect = ({ detail }: CustomEvent<IMenuSelectEventData>) => {
          switch (detail.value) {
            case ParameterActionOption.EDIT: {
              this.props.openParameterEditorModal(parameter);
              break;
            }
            case ParameterActionOption.DELETE: {
              const onSuccess = () => {
                this.props.showToast(t('delete_successful'), none);
              };
              const onError = (err: any) => {
                console.error('Error deleting parameter: ', err);
                const icon = <ForgeIcon name="error" className="toast-error-icon" />;
                this.props.showToast(t('delete_failed'), some(icon), Infinity);
              };
              this.props.deleteParameter(this.props.viewId, parameter.name, onSuccess, onError);
              break;
            }
          }
        };

        const menuOptionBuilder = (
          option: { value: ParameterActionOption; label: string },
          listItem: HTMLElement
        ) => {
          if (option.value === ParameterActionOption.DELETE) {
            listItem.setAttribute('class', `${listItem.getAttribute('class')} delete-option`);
            if (!deleteEnabled) {
              listItem.setAttribute('disabled', '');
              const tooltip = document.createElement('forge-tooltip') as unknown as HTMLElement;
              tooltip.textContent = t('delete_disabled');
              listItem.appendChild(tooltip);
            }
          }
          // wrapping the label in other elements is required for the flyout to work
          return this.jsxToHtmlElementService.wrapJsx(
            <span>{option.label}</span>,
            `menu-option-${option.label}-${parameter.name}-${parameter.viewId}`
          );
        };

        const jsx = (
          <ForgeMenu
            popupClasses="parameter-action-options"
            placement="bottom-right"
            optionBuilder={menuOptionBuilder}
            on-forge-menu-select={onMenuSelect}
            options={menuOptions}
            highlightFirst={false}
          >
            <ForgeIconButton>
              <button
                type="button"
                aria-label="actions"
                className="parameter-action-button"
                id={`${parameter.name}-actions-button`}
              >
                <ForgeIcon name="more_vert"></ForgeIcon>
              </button>
            </ForgeIconButton>
          </ForgeMenu>
        );
        return this.jsxToHtmlElementService.wrapJsx(jsx, `${parameter.name}-${parameter.viewId}-actions`);
      }
    };

    const colConfig = [
      { property: 'name', header: t('table_headers.name'), template: nameTemplate },
      { property: 'dataType', header: t('table_headers.type'), template: dataTypeTemplate },
      { property: 'defaultValue', header: t('table_headers.default_value'), template: defaultValueTemplate },
      { property: 'testValue', header: t('table_headers.test_value'), template: overrideInputTemplate }
    ];

    if (this.props.canModifyParameters) {
      colConfig.push({ property: 'actions', header: t('table_headers.actions'), template: actionsTemplate });
    }

    return (
      <ForgeCard id={'parameters-table-card'} outlined>
        <ForgeTable
          className="parameters-table"
          data={parameters}
          columnConfigurations={colConfig}
        />
      </ForgeCard>
    );
  };

  render() {
    const { parameters: unsortedParameters, query } = this.props;
    // separates out the inherited parameters
    // doing it here rather than in mapStateToProps allows redux to detect equality and prevent unnecessary re-renders
    const parameters = sortClientContextVariables(unsortedParameters, true);
    const hasParameters = parameters.length !== 0;

    return (
      <div className="grid-datasource-components parameters-editor">
        <WithHandlingOfNonVisualStates remoteStatusInfo={this.props.remoteStatusInfo} query={query}>
          <div className="scroll-container filter-scroll-container">
            {hasParameters && this.showParameters(parameters)}
            {!hasParameters && this.createNewElement()}
          </div>
        </WithHandlingOfNonVisualStates>

      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    parameters: state.clientContextInfo.variables,
    viewId: state.view.id,
    contextualEventHandlers: state.contextualEventHandlers,
    query: state.query,
    remoteStatusInfo: state.remoteStatusInfo
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    dispatch,
    showToast: (message: string, icon: Option<JSX.Element>, duration?: number) => {
      dispatch(Actions.showToast(message, icon, duration));
    },
    openParameterModal: () => {
      dispatch(Actions.openModal(OpenModalType.NEW_PARAMETER, none));
    },
    openParameterEditorModal: (parameter: ClientContextVariable) => {
      dispatch(Actions.openModal(OpenModalType.EDIT_PARAMETER, some({ parameterToEdit: parameter })));
    },
    updateParameterOverride: (parameter: ClientContextVariable, override: Override) => {
      dispatch(Actions.updateParameterOverride(parameter, override));
    }
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  return {
    ...ownProps,
    ...stateProps,
    showToast: dispatchProps.showToast,
    deleteParameter: (
      viewId: string,
      parameterName: string,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => {
      dispatchProps.dispatch(
        stateProps.contextualEventHandlers.deleteParameter(viewId, parameterName, onSuccess, onError)
      );
    },
    updateParameterOverride: dispatchProps.updateParameterOverride,
    openParameterModal: dispatchProps.openParameterModal,
    openParameterEditorModal: dispatchProps.openParameterEditorModal,
    parameterDeleteEnabled: (paramName: string) => {
      const param: Parameter = {
        name: paramName,
        table: stateProps.viewId,
        type: 'param'
      };

      return getLastUnAnalyzedAst(stateProps.query)
        .map((un) => {
          return !containsParameter(getAllExpr(un), param);
        })
        .getOrElseValue(true);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ParametersTable);
